import React, { useState, useEffect } from 'react'
import { DatePicker, Space, Card, Col, Row, Select, Tooltip, Tabs, Form, Input, Divider, message, Checkbox } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import Cards from "react-credit-cards";
import axios from "axios";

const { Option } = Select;

function Kirala() {
    const { state } = useLocation();
    const history = useHistory();
    const { TabPane } = Tabs;

    const [creditCard, setcreditCard] = useState({
        cvc: "",
        expiry: "",
        focus: "",
        name: "",
        number: "",
    });
    const [paymentOption, setpaymentOption] = useState(false);
    const [alternativePaymentOption, setalternativePaymentOption] = useState(false);

    const [SelectedCar, setSelectedCar] = useState(state?state.car:[]);
    const [DateDetails, setDateDetails] = useState(state?state.dateDetails:[]);
    const [Locations, setLocations] = useState(state?state.Locations:[]);
    const [SelectedBirtday, setSelectedBirtday] = useState("")
    const [ChildSeatCount, setChildSeatCount] = useState(0);
    const [ChildSeatPrice, setChildSeatPrice] = useState(0);

    const [BabySeatCount, setBabySeatCount] = useState(0);
    const [BabySeatPrice, setBabySeatPrice] = useState(0);

    const [AdditionalDriverCount, setAdditionalDriverCount] = useState(0);
    const [AdditionalDriverPrice, setAdditionalDriverPrice] = useState(0);

    const [NavigationCount, setNavigationCount] = useState(0);
    const [NavigationPrice, setNavigationPrice] = useState(0);

    const [CDWCount, setCDWCount] = useState(0);
    const [CDWPrice, setCDWPrice] = useState(0);

    const [TGICount, setTGICount] = useState(0);
    const [TGIPrice, setTGIPrice] = useState(0);

    const [SCDWCount, setSCDWCount] = useState(0);
    const [SCDWPrice, setSCDWPrice] = useState(0);

    const [TotalPrice, setTotalPrice] = useState(state?state.car.Total_Rental:[]);

    const [paymentOptionPrice, setpaymentOptionPrice] = useState({ boolean: false });
    const [binNumber, setbinNumber] = useState(0);

    const [form] = Form.useForm();

    const [FormValues, setFormValues] = useState({})

    const [activeKey, setactiveKey] = useState("1")
    const [paymentInformationTab, setpaymentInformationTab] = useState(true)


    const [aggrementForCheckbox, setaggrementForCheckbox] = useState({
        aggrementForRent: true,
        aggrementForContact: true,
        aggrementForRegistration: true
    })

    const aggrementCheckbox = async (event) => {
        await setaggrementForCheckbox({
            ...aggrementForCheckbox,
            [event.target.id]: event.target.checked
        })
    }

    const tabChangeFunction = (event) => {
        setactiveKey(event)
    }

    const continueTabFunction = (value) => {
        switch (value) {
            case "1":
                setactiveKey("2")
                break;
            case "2":
                setactiveKey("3")
                setpaymentInformationTab(false);
                break;
            default:
                setactiveKey("1")
                break;
        }
        window.scrollTo(350, 350)
    }

    const birtdayStateFunction = (event) => {
        console.log()
        setSelectedBirtday(moment(event).format("DD-MM-YYYY"))

    }

    const onFinish = (values) => {
        console.log(values);
        setFormValues(values);
        continueTabFunction("2")

    };


    const paymentSelectedOptionFunction = (item) => {
        setTotalPrice(item.totalPrice);
        if (item.installmentNumber === 1) {
            setpaymentOptionPrice({ boolean: false });
        } else {
            setpaymentOptionPrice({ installmentNumber: item.installmentNumber, price: item.price, totalPrice: item.totalPrice });
        }
    };

    const paymentOptionFunction = async (boolean, value) => {
        if (boolean) {
            const paymentbinNumber = `${value.slice(0, 4)}${value.slice(5, 7)}`;
            setbinNumber(paymentbinNumber);
            await axios.post("https://mangayazilim.com/ydzapi/api/home/InstallmentDetail", {
                price: String(TotalPrice),
                binNumber: paymentbinNumber.toString(),
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        const paymentOptionData = res.data?.installmentDetails[0]?.installmentPrices.filter((item, index) => index < 4);
                        setalternativePaymentOption(false);
                        setpaymentOption(paymentOptionData);
                    } else if (res.data.status === "failure") {
                        setalternativePaymentOption(true);
                        setpaymentOption(false);
                    }
                })
                .catch((err) => {
                    setalternativePaymentOption(true);
                    setpaymentOption(false);
                });
        } else {
            setalternativePaymentOption(false);
            setpaymentOption(boolean);
        }
    };

    const payButtonFunction = async () => {

        let randomRez = String(SelectedCar.Rez_ID) + '_' + moment().unix();

        if (creditCard.cvc.length === 3 && creditCard.expiry.length === 5 && creditCard.number.length === 19) {

            const basketItems = [{
                id: SelectedCar.Car_ID.toString(),
                price: parseFloat(TotalPrice).toFixed(2).toString(),
                name: String(SelectedCar.Brand + ' ' + SelectedCar.Type + ' ' + SelectedCar.Fuel + ', ' + SelectedCar.Transmission),
                category1: "Car",
                itemType: "PHYSICAL",
            }]


            const onlineFetchData = {
                installment: paymentOptionPrice.boolean === false ? 1 : parseFloat(paymentOptionPrice.installmentNumber), //Taksit olayı buradan yapılabiliyor. Geçerli değerler: 1, 2, 3, 6, 9.
                binNumber: binNumber.toString(),
                conversationId: randomRez,
                price: parseFloat(TotalPrice).toFixed(2).toString(),
                paidPrice: parseFloat(TotalPrice).toFixed(2).toString(),
                basketId: randomRez, // Üye işyeri tarafından ilgili ödemenin sepetini tanımlamak için kullanılan id'dir. Sipariş numarası veya anlamlı bir değer olabilir. Not: Bir ödeme isteğine maksimum 500 basketItem eklenebilir.
                paymentCard: {
                    cardHolderName: creditCard.name.toString(),
                    cardNumber: creditCard.number.replace(/\s/g, "").toString(),
                    expireYear: "20" + creditCard.expiry.split("/")[1].toString(),
                    expireMonth: creditCard.expiry.split("/")[0].toString(),
                    cvc: creditCard.cvc.toString(),
                },
                buyer: {
                    id: String(randomRez), // Üye işyeri tarafındaki alıcıya ait id.
                    name: String(FormValues.name),
                    surname: String(FormValues.surname),
                    identityNumber: String(FormValues.identity), // Üye işyeri tarafındaki alıcıya ait kimlik (TCKN) numarası.
                    email: String(FormValues.email),
                    gsmNumber: String(FormValues.phone), //"+905350000000",
                    registrationAddress: String(FormValues.address), //"Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
                    city: String(FormValues.city), //"Istanbul",
                    country: "Turkey",
                    zipCode: "34742", //"34732",
                    ip: "85.34.78.112",
                },
                shippingAddress: {
                    description: String(Locations.Location_Name), //"Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
                    zipCode: "34742", //"34742",
                    contactName: String(FormValues.name + ' ' + FormValues.surname), //"Jane Doe",
                    city: String(FormValues.city), //"Istanbul",
                    country: "Turkey",
                },
                billingAddress: {
                    description: String(FormValues.invoiceAddress), //"Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1"
                    zipCode: "34742", //"34742",
                    contactName: String(FormValues.name + ' ' + FormValues.surname), //"Jane Doe",
                    city: String(FormValues.invoiceCity), // "Istanbul",
                    country: "Turkey"
                },
                basketItems: basketItems,
                callbackUrl: "https://mangayazilim.com/ydzapi/api/home/PaymentResult",
            };



            var url = "https://mangayazilim.com/ydzapi/api/home/Initialize";

            console.log('onlineFet: ', JSON.stringify(onlineFetchData))
            console.log("FormValues:", FormValues)

            await axios
                .post(url, onlineFetchData)
                .then((res) => {
                    console.log('res: ', res.data)
                    if (res.data === null || res.data === "") {
                        message.error("Hata! Lütfen girdiğiniz bilgileri kontrol ediniz");
                    } else {
                        const hide = message.loading('İşleminiz gerçekleştiriliyor..', 0);

                        hide();
                        setTimeout(() => {
                            history.push("/odeme", { response: res.data, UserValues: FormValues, DateDetails: DateDetails, Locations: Locations,SelectedBirtday:SelectedBirtday, onlineFetchData: onlineFetchData, selectedCar: SelectedCar, options: { ChildSeatCount, BabySeatCount, AdditionalDriverCount, NavigationCount, CDWCount, TGICount, SCDWCount } })
                        }, 1000);
                    }
                })
                .catch((err) => {
                    message.error(String(err));
                });
        }
    };

    const cartInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, "");
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 5) return currentValue;
            else if (cvLength < 9) return `${currentValue.slice(0, 4)} ${currentValue.slice(4)}`;
            else if (cvLength < 13) return `${currentValue.slice(0, 4)} ${currentValue.slice(4, 8)} ${currentValue.slice(8)}`;
            return `${currentValue.slice(0, 4)} ${currentValue.slice(4, 8)} ${currentValue.slice(8, 12)} ${currentValue.slice(12, 16)}`;
        }
    };


    const cartDate = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, "");
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 3) return currentValue;
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}`;
        }
    };

    const CVC = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, "");
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 3) return `${currentValue.slice(0, 3)}`;
            return `${currentValue.slice(0, 3)}`
        }
    };

    const handleCartInputFocus = (event) => {
        setcreditCard({
            ...creditCard,
            focus: event.target.name,
        });
    };

    const handleCartInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "number") {
            setcreditCard({
                ...creditCard,
                [name]: cartInput(value + " ", value + ""),
            });
            if (cartInput(value + " ", value + "").length === 19) {

                paymentOptionFunction(true, cartInput(event.target.value + " ", event.target.value + ""));
            } else {

                paymentOptionFunction(false, cartInput(event.target.value + " ", event.target.value + ""));
            }
        } else {
            name === "expiry"
                ? setcreditCard({
                    ...creditCard,
                    [name]: cartDate(value + " ", value + ""),
                })
                : name === "cvc"
                    ? setcreditCard({
                        ...creditCard,
                        [name]: CVC(value + " ", value + ""),
                    })
                    : setcreditCard({
                        ...creditCard,
                        [name]: value,
                    });
        }
    };





    const CalculatePrice = () => {
        let newData = parseInt(state?state.car.Total_Rental:0) + parseInt(BabySeatPrice) + parseInt(ChildSeatPrice) + parseInt(AdditionalDriverPrice) + parseInt(NavigationPrice) + parseInt(CDWPrice) + parseInt(SCDWPrice) + parseInt(TGIPrice)

        console.log('ew:D', newData)
        setTotalPrice(parseFloat(newData).toFixed(0));
    }

    useEffect(() => {
        CalculatePrice()
        console.log('values: ', FormValues)
    }, [ChildSeatPrice, BabySeatPrice, AdditionalDriverPrice, NavigationPrice, CDWPrice, SCDWPrice, TGIPrice])


    return (
        <div>
            <main>

                <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${'img/bg/breadcrumb_bg.jpg'})` }} data-background="img/bg/breadcrumb_bg.jpg">
                    <div className="container" style={{ paddingTop: 50 }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2>Hemen Kirala</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/araclar">Araçlar</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Rezervasyon Yap</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="author-profile-area gray-lite-bg pt-120 pb-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5 col-md-9 order-2 order-lg-0">
                                <aside className="author-profile-sidebar">
                                    <div className="author-profile-wrap">
                                        <div className="author-profile-thumb">
                                            <img height={133} width={133} src={SelectedCar.Image_Path} alt="" />
                                        </div>
                                        <h5 className="name">{SelectedCar.Brand} {SelectedCar.Type}</h5>
                                        <span className="des">{SelectedCar.Transmission} Vites, {SelectedCar.Fuel}</span>
                                        <div className="location">
                                            <p><i className="fas fa-map-marker-alt"></i>Alış Yeri: {Locations.Location_Name}</p>
                                            <p><i className="fas fa-clock"></i>Alış Tarihi: {DateDetails.Pickup_Day}/{DateDetails.Pickup_Month}/{DateDetails.Pickup_Year} {DateDetails.Pickup_Hour}:{DateDetails.Pickup_Min}</p>
                                        </div>
                                        <div className="location">
                                            <p><i className="fas fa-map-marker-alt"></i>İade Yeri: {Locations.Location_Name}</p>
                                            <p><i className="fas fa-clock"></i>İade Tarihi: {DateDetails.Drop_Off_Day}/{DateDetails.Drop_Off_Month}/{DateDetails.Drop_Off_Year} {DateDetails.Drop_Off_Hour}:{DateDetails.Drop_Off_Min}</p>
                                        </div>
                                        <div className="author-profile-list">
                                            <ul>
                                                <li><i className="far fa-money-bill-alt"></i>Günlük Ücret: <span>{SelectedCar.Daily_Rental} TL</span></li>
                                                <li><i className="far fa-calendar-check"></i>Kira Gün <span>{SelectedCar.Days}</span></li>
                                                <li><i className="far fa-money-bill-alt"></i>Araç Ücreti <span>{SelectedCar.Total_Rental} TL</span></li>
                                                <li style={{ marginTop: 50 }}>TOPLAM TUTAR <span style={{ fontWeight: '700' }}>{TotalPrice} TL</span></li>
                                            </ul>
                                        </div>

                                        <div className="author-contact-info">
                                            <p>Devam et butonuna basarak ödeme ve rezervasyon işleminizi tamamlayabilirsiniz.</p>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-9" style={{ marginTop: -75 }}>
                                <Tabs tabBarStyle={{ fontWeight: '700', color: '#62D46C' }} activeKey={activeKey} onChange={(e) => tabChangeFunction(e)} >
                                    <TabPane tab="Kiralama Bilgileri" key="1" >
                                        <div className="inventory-meta-wrap mb-35">
                                            <div className="inventory-top-meta">
                                                <ul>
                                                    <li className="find">İlave Hizmetler</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="inventory-list-item">
                                            <Row gutter={24}>
                                                <Col span={6}>
                                                    <Card title="Hizmet" bordered={false}>
                                                        Bebek Koltuğu
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="Adet" bordered={false}>
                                                        <Select defaultValue={0} onChange={(e) => {
                                                            setBabySeatCount(e);
                                                            setBabySeatPrice(parseInt(SelectedCar.Baby_Seat / SelectedCar.Days * e) * SelectedCar.Days);
                                                            CalculatePrice();
                                                        }}>
                                                            <Option value={0}>0</Option>
                                                            <Option value={1}>1</Option>
                                                            <Option value={2}>2</Option>
                                                            <Option value={3}>3</Option>
                                                        </Select>
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="Fiyat" bordered={false}>
                                                        {parseFloat(SelectedCar.Baby_Seat / SelectedCar.Days).toFixed(2)}  TL
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="Toplam" bordered={false}>
                                                        {parseFloat(BabySeatPrice).toFixed(2)} TL
                                                    </Card>
                                                </Col>

                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        Çocuk Koltuğu
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        <Select defaultValue={0} onChange={(e) => {
                                                            setChildSeatCount(e);
                                                            setChildSeatPrice(parseInt(SelectedCar.Baby_Seat / SelectedCar.Days * e) * SelectedCar.Days);
                                                            CalculatePrice();
                                                        }}>
                                                            <Option value={0}>0</Option>
                                                            <Option value={1}>1</Option>
                                                            <Option value={2}>2</Option>
                                                            <Option value={3}>3</Option>
                                                        </Select>
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(SelectedCar.Baby_Seat / SelectedCar.Days).toFixed(2)} TL
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(ChildSeatPrice).toFixed(2)} TL
                                                    </Card>
                                                </Col>

                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        Ek Sürücü
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        <Select defaultValue={0} onChange={(e) => {
                                                            setAdditionalDriverCount(e);
                                                            setAdditionalDriverPrice(parseInt(SelectedCar.Additional_Driver / SelectedCar.Days * e) * SelectedCar.Days);
                                                            CalculatePrice();
                                                        }}>
                                                            <Option value={0}>0</Option>
                                                            <Option value={1}>1</Option>
                                                            <Option value={2}>2</Option>
                                                        </Select>
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(SelectedCar.Additional_Driver / SelectedCar.Days).toFixed(2)} TL
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(AdditionalDriverPrice).toFixed(2)} TL
                                                    </Card>
                                                </Col>

                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        Navigasyon
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        <Select defaultValue={0} onChange={(e) => {
                                                            setNavigationCount(e);
                                                            setNavigationPrice(parseInt(SelectedCar.Navigation / SelectedCar.Days * e) * SelectedCar.Days);
                                                            CalculatePrice();
                                                        }}>
                                                            <Option value={0}>0</Option>
                                                            <Option value={1}>1</Option>
                                                        </Select>
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(SelectedCar.Navigation / SelectedCar.Days).toFixed(2)} TL
                                                    </Card>
                                                </Col>
                                                <Col span={6}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(NavigationPrice).toFixed(2)} TL
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="inventory-meta-wrap mb-35">
                                            <div className="inventory-top-meta">
                                                <ul>
                                                    <li className="find">Güvenceler</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="inventory-list-item">
                                            <Row gutter={[8, 24]}>
                                                <Col span={5}>
                                                    <Card title="Güvence" bordered={false}>
                                                        Mini Hasar Güvencesi
                                                    </Card>
                                                </Col>
                                                <Col span={4}>
                                                    <Card title="Bilgi" bordered={false}>
                                                        <Tooltip placement="top" title="750 TL ye kadar oluşan hasarlarda kiracı beyanı ile onarım sağlama avantajı sunan güvencesi.">
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </Card>
                                                </Col>
                                                <Col span={5}>
                                                    <Card title="Adet" bordered={false}>
                                                        <Select defaultValue={0} onChange={(e) => {
                                                            setCDWCount(e);
                                                            setCDWPrice(parseInt(SelectedCar.CDW / SelectedCar.Days * e) * SelectedCar.Days);
                                                            CalculatePrice();
                                                        }}>
                                                            <Option value={0}>0</Option>
                                                            <Option value={1}>1</Option>
                                                        </Select>
                                                    </Card>
                                                </Col>
                                                <Col span={4}>
                                                    <Card title="Fiyat" bordered={false}>
                                                        {parseFloat(SelectedCar.CDW / SelectedCar.Days).toFixed(2)}  TL
                                                    </Card>
                                                </Col>
                                                <Col span={5}>
                                                    <Card title="Toplam" bordered={false}>
                                                        {parseFloat(CDWPrice).toFixed(2)} TL
                                                    </Card>
                                                </Col>

                                                <Col span={5}>
                                                    <Card title="" bordered={false}>
                                                        Lastik Cam Far Sigortası (LCF)
                                                    </Card>
                                                </Col>
                                                <Col span={4}>
                                                    <Card title="" bordered={false}>
                                                        <Tooltip placement="top" title="Trafik Kazasına bağlı olmaksızın oluşan Lastik, Cam ve Far hasarlarını güvence altına alır.">
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </Card>
                                                </Col>
                                                <Col span={5}>
                                                    <Card title="" bordered={false}>
                                                        <Select defaultValue={0} onChange={(e) => {
                                                            setTGICount(e);
                                                            setTGIPrice(parseInt(SelectedCar.TGI / SelectedCar.Days * e) * SelectedCar.Days);
                                                            CalculatePrice();
                                                        }}>
                                                            <Option value={0}>0</Option>
                                                            <Option value={1}>1</Option>
                                                        </Select>
                                                    </Card>
                                                </Col>
                                                <Col span={4}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(SelectedCar.TGI / SelectedCar.Days).toFixed(2)}  TL
                                                    </Card>
                                                </Col>
                                                <Col span={5}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(TGIPrice).toFixed(2)} TL
                                                    </Card>
                                                </Col>

                                                <Col span={5}>
                                                    <Card title="" bordered={false}>
                                                        Tam Kaza Güvencesi (SCDW)
                                                    </Card>
                                                </Col>
                                                <Col span={4}>
                                                    <Card title="" bordered={false}>
                                                        <Tooltip placement="top" title="Kaza ile ilgili raporların tam ve eksiksiz getirilmesi ve asli kusurlu olunmaması koşulu ile tüm kaza hallerini güvence altına alır.">
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </Card>
                                                </Col>
                                                <Col span={5}>
                                                    <Card title="" bordered={false}>
                                                        <Select defaultValue={0} onChange={(e) => {
                                                            setSCDWCount(e);
                                                            setSCDWPrice(parseInt(SelectedCar.SCDW / SelectedCar.Days * e) * SelectedCar.Days);
                                                            CalculatePrice();
                                                        }}>
                                                            <Option value={0}>0</Option>
                                                            <Option value={1}>1</Option>
                                                        </Select>
                                                    </Card>
                                                </Col>
                                                <Col span={4}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(SelectedCar.SCDW / SelectedCar.Days).toFixed(2)}  TL
                                                    </Card>
                                                </Col>
                                                <Col span={5}>
                                                    <Card title="" bordered={false}>
                                                        {parseFloat(SCDWPrice).toFixed(2)} TL
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="">
                                            <Row gutter={[8, 24]}>
                                                <Col span={6}>
                                                </Col>
                                                <Col span={6}>
                                                </Col>
                                                <Col span={6}>
                                                </Col>
                                                <Col span={6} >
                                                    <button className="btn" onClick={() => continueTabFunction("1")} htmlType="submit">Devam et</button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Kişisel Bilgiler" key="2">
                                        <div className="inventory-meta-wrap mb-35">
                                            <div className="inventory-top-meta">
                                                <ul>
                                                    <li className="find">Kişisel Bilgiler</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="inventory-list-item">
                                            <section className="loan-calculator-area pb-120">
                                                <br />
                                                <div className="col-12">
                                                    <div className="contact-form-wrap">
                                                        <Form onFinish={onFinish} form={form} className="login-form">
                                                            <div className="row">
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="name"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen Ad giriniz'
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*Ad" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="surname"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen Soyad giriniz'
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*Soyad" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="email"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen Mail Adresi giriniz',
                                                                                type: 'email'
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*Mail Adresi" />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="mobilePhone"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen Cep Telefonu giriniz',
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*Cep Telefonu" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="phone"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen Telefon Numarası giriniz',
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*Telefon Numarası" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="birthDate">
                                                                        <Space direction="vertical" size={12}>
                                                                            <DatePicker
                                                                                onChange={e => birtdayStateFunction(e)}
                                                                                allowClear
                                                                                placeholder="Doğum Tarihi"
                                                                                style={{
                                                                                    height: "auto",
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    borderRadius: "0px",
                                                                                    cursor: "pointer",
                                                                                    fontSize: "17px",
                                                                                    margin: "0px",
                                                                                    padding: "0px"
                                                                                }}
                                                                                locale={locale}
                                                                                format="DD-MM-YYYY" />
                                                                        </Space>
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="identity"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen TC Kimlik No giriniz'
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*TC Kimlik No" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="city"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Şehir bilgisi giriniz',
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*Şehir" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="flightNo"
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                message: 'Lütfen Uçuş No giriniz'
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="Uçuş No" />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="licenseNo"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen Ehliyet No giriniz'
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="*Ehliyet No" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-8">
                                                                    <Form.Item name="address"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Lütfen Adres giriniz',
                                                                                min: 18
                                                                            },
                                                                        ]}>
                                                                        <Input.TextArea Rows={4} placeholder="*Adres" />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className="inventory-meta-wrap mb-35">
                                                                <div className="inventory-top-meta">
                                                                    <ul>
                                                                        <li className="find">Fatura Bilgileri</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="companyName"
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                message: 'Lütfen Firma Adı giriniz'
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="Firma Adı" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="taxPlace"
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                message: 'Vergi Dairesi giriniz',
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="Vergi Dairesi" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="taxNumber"
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                message: 'Vergi Numarası giriniz',
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="Vergi Numarası" />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="invoiceCity"
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                message: 'Şehir bilgisi giriniz',
                                                                            },
                                                                        ]}>
                                                                        <Input placeholder="Şehir" />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-8">
                                                                    <Form.Item name="invoiceAddress"
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                message: 'Lütfen Adres giriniz'
                                                                            },
                                                                        ]}>
                                                                        <Input.TextArea Rows={4} placeholder="Fatura Adresi" />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="aggrementForRent"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                transform: value => (aggrementForCheckbox.aggrementForRent || undefined),  // Those two lines
                                                                                type: 'boolean',
                                                                                message: 'Lütfen sözleşmeyi onaylayın.'
                                                                            },
                                                                        ]}>
                                                                        <Checkbox checked={aggrementForCheckbox.aggrementForRent} onChange={e => aggrementCheckbox(e)}>
                                                                        <span style={{color: "rgba(0, 0, 0, 0.85)"}}>Kiralama Sözleşmesini ve Bilgilendirme Formunu Okudum, Kabul Ediyorum.</span>
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="aggrementForContact"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                transform: value => (aggrementForCheckbox.aggrementForContact || undefined),  // Those two lines
                                                                                type: 'boolean',
                                                                                message: 'Lütfen sözleşmeyi onaylayın.'
                                                                            },
                                                                        ]}>
                                                                        <Checkbox  checked={aggrementForCheckbox.aggrementForContact} onChange={e => aggrementCheckbox(e)}>
                                                                            <span style={{color: "rgba(0, 0, 0, 0.85)"}}>Tüm iletişim kanallarından (SMS, Telefon, Ebülten) kampanyalar hakkında bildirim almayı kabul ediyorum.</span>
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="form-grp col-md-4">
                                                                    <Form.Item name="aggrementForRegistration"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                transform: value => (aggrementForCheckbox.aggrementForRegistration || undefined),  // Those two lines
                                                                                type: 'boolean',
                                                                                message: 'Lütfen sözleşmeyi onaylayın.'
                                                                            },
                                                                        ]}>
                                                                        <Checkbox checked={aggrementForCheckbox.aggrementForRegistration} onChange={e => aggrementCheckbox(e)}>
                                                                        <span style={{color: "rgba(0, 0, 0, 0.85)"}}>Üyeliğinizi onaylayın, indirimlerden faydalanın.</span>
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-grp col-md-9">
                                                                </div>
                                                                <div className="form-grp col-md-3">
                                                                    <button className="btn" htmlType="submit">Devam et</button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Ödeme" key="3" disabled={paymentInformationTab}>
                                        <div className="inventory-list-item">
                                            <div id="PaymentForm" className="payment-form">
                                                <form>
                                                    <Divider orientation="center"></Divider>
                                                    <Row gutter={[48, 48]}>
                                                        <Col flex="auto">
                                                            <Cards
                                                                placeholders={{ name: "Ad Soyad", cvc: "Cvc" }}
                                                                cvc={creditCard.cvc}
                                                                expiry={creditCard.expiry}
                                                                focused={creditCard.focus}
                                                                name={creditCard.name}
                                                                number={creditCard.number} />
                                                                
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Form.Item>
                                                                <Input
                                                                    type="text"
                                                                    className="card-input"
                                                                    name="name"
                                                                    placeholder="Ad Soyad"
                                                                    value={creditCard.name}
                                                                    onChange={(e) => handleCartInputChange(e)}
                                                                    onFocus={(e) => handleCartInputFocus(e)} />
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Input
                                                                    type="tel"
                                                                    name="number"
                                                                    placeholder="Kart Numarası"
                                                                    className="card-input"
                                                                    value={creditCard.number}
                                                                    onChange={(e) => handleCartInputChange(e)}
                                                                    onFocus={(e) => handleCartInputFocus(e)} />
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Input
                                                                    type="tel"
                                                                    name="expiry"
                                                                    placeholder="Son Kullanma Tarihi"
                                                                    className="card-input card-date"
                                                                    value={creditCard.expiry}
                                                                    onChange={(e) => handleCartInputChange(e)}
                                                                    onFocus={(e) => handleCartInputFocus(e)} />
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Input
                                                                    type="tel"
                                                                    className="card-input card-cvc"
                                                                    name="cvc"
                                                                    placeholder="CVC"
                                                                    value={creditCard.cvc}
                                                                    onChange={(e) => handleCartInputChange(e)}
                                                                    onFocus={(e) => handleCartInputFocus(e)} />
                                                            </Form.Item>

                                                            <div className="author-profile-btn" onClick={() => payButtonFunction()}>
                                                                <a href={() => false} className="btn">Satın Al - {TotalPrice} TL</a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </div>
                                        </div>
                                        {paymentOption ? (
                                            <div className="paymentOptionTable mt-5">
                                                <div className="inventory-list-item">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Taksit Sayısı</th>
                                                                <th scope="col">Aylık Ödeme</th>
                                                                <th scope="col">Toplam</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {paymentOption.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="custom-control-input"
                                                                                    name="paymentMethodRadio"
                                                                                    id={"paymentRadio" + index}
                                                                                    defaultChecked={index === 0 ? true : false}
                                                                                    onChange={(e) => paymentSelectedOptionFunction(item)}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor={"paymentRadio" + index}>
                                                                                    {item.installmentNumber === 1 ? "Tek çekim" : item.installmentNumber + " Taksit"}
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                        <td>{parseFloat(item.price).toFixed(2)}</td>
                                                                        <td>{parseFloat(item.totalPrice).toFixed(2)}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : alternativePaymentOption ? (
                                            <div className="paymentOptionTable mt-5">
                                                <h4 className="mb-2">TAKSİT SEÇİMİ</h4>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Taksit Sayısı</th>
                                                            <th scope="col">Aylık Ödeme</th>
                                                            <th scope="col">Toplam</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="radio" className="custom-control-input" name="paymentMethodRadio" id={"paymentRadio"} defaultChecked={true} />
                                                                    <label className="custom-control-label" htmlFor={"paymentRadio"}>
                                                                        {"Tek çekim"}
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            {/* <td>{parseFloat((firstOrder ? 0.95 : 1) * totalPrice * 1.18).toFixed(2)}</td> */}
                                                            {/* <td>{parseFloat((firstOrder ? 0.95 : 1) * totalPrice * 1.18).toFixed(2)}</td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    )
}

export default Kirala;