import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";

const Thanks = ({ location }) => {

  const [isPayment, setisPayment] = useState(location.state ? location.state.payment : false);
  return (
    <Fragment>
      <div className="cart-main-area pt-90 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {isPayment === "success" ? (
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <CheckCircleTwoTone twoToneColor="#62D46C" style={{ fontSize: 96 }} />
                  </div>
                  <div className="item-empty-area__text">
                    <h1>Ödeme Başarılı</h1>
                    <h6>Rezervasyonunuz başarıyla yapılmıştır.</h6>
                    <Link to={"/"}>
                      <button style={{ marginTop: 30 }} className="btn">
                        ANASAYFAYA DÖN
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <CloseCircleOutlined color="#da3031" style={{ fontSize: 96 }} />
                  </div>
                  <div className="item-empty-area__text">
                    <h1>Ödeme Yapılamadı</h1>

                    <Link to={"/"}>
                      <button style={{ marginTop: 30 }} className="btn">
                        ANASAYFAYA DÖN
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Thanks;
