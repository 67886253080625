import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { DatePicker, Space, message } from 'antd';
import { useHistory, Link } from "react-router-dom";

function Home() {
    let history = useHistory();

    const [Locations, setLocations] = useState([]);
    const [WorkDays, setWorkDays] = useState([]);

    const [CarsList, setCarsList] = useState([]);
    const [SearchCarsList, setSearchCarsList] = useState([]);
    const [SpliceLimit, setSpliceLimit] = useState(6);
    const [Categories, setCategories] = useState([]);
    const [SelectedCat, setSelectedCat] = useState("");

    const tomorrow = moment().add(1, 'days');
    const nextTomorrow = moment().add(2, 'days');

    const [selectedStartTime, setselectedStartTime] = useState(moment(tomorrow).set({ h: 10, m: 0 }));
    const [selectedEndTime, setselectedEndTime] = useState(moment(nextTomorrow).set({ h: 10, m: 0 }));
    const [type, settype] = useState(1);
    const [SelectedPickUpLocationID, setSelectedPickUpLocationID] = useState("")
    const [SelectedDropLocationID, setSelectedDropLocationID] = useState("");

    const GetCars = async () => {
        let myCars = [];
        let catList = [];


        await axios.get("https://mangayazilim.com/ydzapi/api/Home/GetCars")
            .then((response) => {

                Object.values(response.data.CityRent.Cars).forEach((item) => {
                    myCars.push(item);

                    if (catList.indexOf(item.Group_Str) === -1) {
                        catList.push(item.Group_Str)
                    }
                })
            }).finally(() => {
                setCarsList(myCars);
                setSearchCarsList(myCars);
                setCategories(catList);
            })
            .catch(err => {
                console.log('Get Cars Error: ', err)
            })
    }

    const GoToSearch = async () => {
        const pickupDate = moment(selectedStartTime).format("DD-MM-YYYYTHH:mm")
        const dropDate = moment(selectedEndTime).format("DD-MM-YYYYTHH:mm");

        if (pickupDate !== undefined && pickupDate !== null && dropDate !== undefined && dropDate !== null) {
            const hide = message.loading('İşleminiz gerçekleştiriliyor..', 0);

            let dataModel = {
                "Pickup_ID": String(SelectedPickUpLocationID),
                "Pickup_Day": String(pickupDate).split('T')[0].split('-')[0],
                "Pickup_Month": String(pickupDate).split('T')[0].split('-')[1],
                "Pickup_Year": String(pickupDate).split('T')[0].split('-')[2],
                "Pickup_Hour": String(pickupDate).split('T')[1].split(':')[0],
                "Pickup_Min": String(pickupDate).split('T')[1].split(':')[1],
                "Drop_Off_ID": String(SelectedDropLocationID),
                "Drop_Off_Day": String(dropDate).split('T')[0].split('-')[0],
                "Drop_Off_Month": String(dropDate).split('T')[0].split('-')[1],
                "Drop_Off_Year": String(dropDate).split('T')[0].split('-')[2],
                "Drop_Off_Hour": String(dropDate).split('T')[1].split(':')[0],
                "Drop_Off_Min": String(dropDate).split('T')[1].split(':')[1],
                "Currency": "TL"
            }

            await axios.post("https://mangayazilim.com/ydzapi/api/Home/Search", dataModel)
                .then((res) => {
                    if (res.data === "failure") {
                        hide();
                        message.error('Seçtiğiniz kritelerde göre arama sonucu bulunamadı. Lütfen farklı gün ve tarih seçiniz.');
                    } else {
                        hide();
                        setTimeout(() => {
                            history.push("/sonuclar", { cars: res.data.Grsrent.Cars, dateDetails: dataModel, Locations: Locations })
                        }, 1000);
                    }
                })
                .catch((err) => {
                    hide();
                    console.log('search err:', err);
                    message.error(String(err));
                })
        } else {
            message.error('Lütfen tüm bilgileri doldurun.');
            console.log('err')
        }
    }

    function onOk(value) {

        const selectedDay = moment(value);
        const dayName = moment(value).format("dddd");

        let seciliSaat = parseFloat(String(moment(selectedDay).format("HH:mm")).replace(':', ''));

        function showSucces() {
            if (type === 1) {
                setselectedStartTime(value)
            } else {
                setselectedEndTime(value);
            }
            message.success(`${type === 1 ? 'Alış' : 'İade'} tarihi ve saati müsait.`);
        }

        function showError(hours) {
            message.error(`${dayName} günleri ${hours.start} ve ${hours.end} arasında çalışmaktayız. Lütfen bu saat aralığında seçiniz.`);
            return false;
        }

        switch (parseFloat(moment(selectedDay).format("d"))) {
            case 0:
                if (seciliSaat < parseFloat(String(WorkDays[6].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[6].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[6].Work_Time_Start,
                        end: WorkDays[6].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 1:
                if (seciliSaat < parseFloat(String(WorkDays[0].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[0].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[0].Work_Time_Start,
                        end: WorkDays[0].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 2:
                if (seciliSaat < parseFloat(String(WorkDays[1].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[1].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[1].Work_Time_Start,
                        end: WorkDays[1].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 3:
                if (seciliSaat < parseFloat(String(WorkDays[2].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[2].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[2].Work_Time_Start,
                        end: WorkDays[2].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 4:
                if (seciliSaat < parseFloat(String(WorkDays[3].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[3].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[3].Work_Time_Start,
                        end: WorkDays[3].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 5:
                if (seciliSaat < parseFloat(String(WorkDays[4].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[4].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[4].Work_Time_Start,
                        end: WorkDays[4].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 6:
                if (seciliSaat < parseFloat(String(WorkDays[5].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[5].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[5].Work_Time_Start,
                        end: WorkDays[5].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            default:
                break;
        }
    }


    const GetLocations = async () => {
        await axios.get("https://mangayazilim.com/ydzapi/api/Home/GetLocations")
            .then((res) => {
                let days = Object.values(res.data.AytuRent.Location.WorkDays)
                setWorkDays(days[0]);
                setLocations(res.data.AytuRent.Location);
                setSelectedPickUpLocationID(res.data.AytuRent.Location.Location_ID);
                setSelectedDropLocationID(res.data.AytuRent.Location.Location_ID);
            })
            .catch((err) => console.log('Get Location Error: ', err))
    }

    const FetchAllData = async () => {
        await GetCars();
        await GetLocations();
    }

    useLayoutEffect(() => {
        FetchAllData();
    }, [])

    return (
        <div>
            <main>
                <section className="slider-area">
                    <div className="slider-active">
                        {console.log("")}
                        <div className="single-slider" style={{ padding: "100px 0 255px", minHeight: "unset" }}>
                            <div className="slider-angle-shape" data-background="img/slider/slider_shape.png"></div>
                            <div className="slider-bg" style={{ backgroundImage: `url(${'img/images/ydz_otomotiv_bg2.jpg'})`, transition: "unset", transform: "unset" }} data-background="img/slider/ydz_otomotiv_bg1.jpeg"></div>
                            <div className="container custom-container">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12">
                                        <div className="slider-content">
                                            <span data-animation="fadeInUp" data-delay=".3s">ARAÇ KİRALAMAK</span>
                                            <h2 className="background-h2" data-animation="fadeInUp" data-delay=".6s">Hiç Bu Kadar Kolay Olmamıştı.</h2>
                                            <p style={{ marginBottom: "1rem" }} data-animation="fadeInUp" data-delay=".9s">YDZ Otomotiv’le dilediğin aracı en uygun fiyata kiralamak artık çok kolay!</p>
                                            <Link to="/araclar">
                                                <a href={() => false} className="btn" data-animation="fadeInUp" data-delay="1.2s"><span>+</span> Tüm Araçları Gör</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="car-search-area gray-bg">
                    <div className="container new-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="car-search-wrap">
                                    <div className="small-title mb-20">
                                        <h4><i className="flaticon-searching-car"></i> ARAÇ MI <span>ARIYORSUNUZ?</span></h4>
                                    </div>
                                    <form action="#" className="car-search-form">
                                        <div className="row align-items-end">
                                            <div className="col custom-col-5">
                                                <label>ALIŞ LOKASYONU</label>
                                                <select name="name" className="selected selected-border-none">
                                                    <option value={Locations.Location_Name}>{Locations.Location_Name}</option>
                                                </select>
                                            </div>
                                            <div className="col custom-col-5">
                                                <label>ALIŞ TARİHİ</label>
                                                <Space direction="vertical" size={12} className="w-100" >
                                                    <DatePicker
                                                        className="selected-border-none"
                                                        onClick={() => settype(1)}
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            backgroundColor: '#F3F3F3',
                                                            padding: '22px 40px 22px 20px'
                                                        }}
                                                        // className="selected"
                                                        defaultValue={selectedStartTime}
                                                        showTime={{ format: 'HH:mm' }}
                                                        allowClear
                                                        locale={locale}
                                                        format="YYYY-MM-DD HH:mm"
                                                        onOk={onOk}
                                                    />
                                                </Space>
                                            </div>
                                            <div className="col custom-col-5">
                                                <label>İADE LOKASYONU</label>
                                                <select name="name" className="selected selected-border-none">
                                                    <option value={Locations.Location_Name}>{Locations.Location_Name}</option>
                                                </select>
                                            </div>
                                            <div className="col custom-col-5">
                                                <label>İADE TARİHİ</label>
                                                <Space direction="vertical" size={12} className="w-100">
                                                    <DatePicker
                                                        onClick={() => settype(0)}
                                                        className="selected-border-none"
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            backgroundColor: '#F3F3F3',
                                                            padding: '22px 40px 22px 20px'
                                                        }}
                                                        // className="selected"
                                                        defaultValue={selectedEndTime}
                                                        showTime={{ format: 'HH:mm' }}
                                                        allowClear
                                                        locale={locale}
                                                        format="YYYY-MM-DD HH:mm"
                                                        onOk={onOk}
                                                    />
                                                </Space>
                                            </div>
                                            <div className="col custom-col-5">
                                                <a href={() => false} onClick={GoToSearch} className="btn">ARAÇLARI LİSTELE</a>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="car-search-map-icon"><i className="flaticon-location"></i></div>
                                    <div className="car-search-shape"><img src="img/images/car_search_shape.png" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="latest-cars-area pt-120 pb-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6">
                                <div className="section-title text-center mb-35">
                                    <h2 className="overlay-title">Tüm Araçlar</h2>
                                    <span className="sub-title">YDZ OTOMOTİV FARKIYLA</span>
                                    <h2 className="title">Yeni Eklenen Araçlar</h2>
                                </div>
                                <div className="latest-car-menu-wrap">
                                    <div className="latest-car-menu-active">
                                        <button onClick={() => {
                                            setSelectedCat("");
                                            setSearchCarsList(CarsList);
                                        }} className={SelectedCat !== "" ? null : "active"}>TÜM ARAÇLAR</button>
                                        {Categories.map((cat) => {
                                            return (<button key={cat} className={SelectedCat !== cat ? null : "active"} onClick={() => {
                                                let filtered = CarsList.filter(q => q.Group_Str === cat);
                                                setSelectedCat(cat);
                                                setSearchCarsList(filtered);
                                            }}  >{cat}</button>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="latest-cars-wrapper">
                            <div className="row latest-car-items-active">
                                {Object.values(SearchCarsList.sort((a, b) => b.Brand - a.Brand)).splice(0, parseFloat(SpliceLimit)).map((car) => {
                                    return (
                                        <div key={car.ID} className="col-lg-4 col-md-6 grid-item grid-sizer cat-one">
                                            <div className="latest-car-item mb-60">
                                                <div className="latest-car-thumb mb-25">

                                                    <img src={car.Image1_Path} alt="" />

                                                </div>
                                                <div className="latest-car-content">
                                                    <div className="latest-car-content-top">
                                                        <h5>{`${car.Brand} ${car.Type}`}</h5>
                                                        <li className="price">{car.Provizyon} TL</li>
                                                    </div>
                                                    <p>'den başlayan <span>fiyatlarla</span></p>
                                                    <div className="latest-car-meta">
                                                        <ul>
                                                            <li><i className="flaticon-settings"></i> {car.Transmission} Vites</li>
                                                            <li><i className="flaticon-gasoline-pump"></i> {car.Fuel}</li>
                                                            <li><i className="flaticon-motorway"></i> İstanbul</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {SpliceLimit < CarsList.length &&
                                <div className="row">
                                    <div className="col-12">
                                        <div className="latest-car-btn text-center mt-10">
                                            <a href={() => false} onClick={() => setSpliceLimit(SpliceLimit + 6)} className="btn">DİĞER ARAÇLARI GÖSTER</a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Home;