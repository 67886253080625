import React, { useState } from 'react'
import { Form, Input, message } from 'antd';
import axios from 'axios';

function Contact() {
    const [form] = Form.useForm();
    const [isLoading, setisLoading] = useState(false);

    const onFinish = async (values) => {

        setisLoading(true);
        const hide = message.loading('Mesajınız gönderiliyor..', 0);

        axios.post("https://us-central1-avcilarkuyumcusu-5f835.cloudfunctions.net/app/sendEmailYDZ", {
            formData: {
                email: values.email,
                message: values.message,
                subject: "İletişim Formu",
                name: values.name
            }, type: "contact"
        }).then((res) => {
            console.log('res: ', res)
            if (res.status === 200) {
                hide();
                form.resetFields();
                setisLoading(false);
                message.success('Teşekkürler, mesajınız başarılı bir şekilde bize ulaştı.');
            } else {
                hide();
                setisLoading(false);
                message.error('Mesaj gönderilirken bir hata oluştu, lütfen tekrar deneyin.');
            }
        })
            .catch((err) => {
                hide();
                setisLoading(false);
                message.error(String(err));
            })
    };

    return (
        <div>
            <main>
                <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${'img/bg/breadcrumb_bg.jpg'})` }} data-background="img/bg/breadcrumb_bg.jpg">
                    <div className="container" style={{ paddingTop: 50 }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2>Bize Ulaşın</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Ana Sayfa</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">İletişim</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-area pt-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-info-wrap">
                                    <div className="section-title text-left mb-25">
                                        <span className="sub-title">İLETİŞİM BİLGİLERİMİZ</span>
                                        <h2 className="title">Bize Ulaşın</h2>
                                        <div className="line"><img src="img/images/title_line.png" alt="" /></div>
                                    </div>
                                    <p>Sorularınız ve şikayetleriniz için çalışma saatlerimiz içerisinde bizimle iletişime geçebilirsiniz.</p>
                                    <ul className="contact-info-list">
                                        <li>
                                            <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                            <div className="content">
                                                <h5>İstanbul Şubesi</h5>
                                                <p>Kurtköy, Koru Sk. No:11, 34912 Pendik/İstanbul</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                            <div className="content">
                                                <h5>Müşteri Destek Hattı</h5>
                                                <p><a href="tel:905434721199">+90 (543) 472 11 99</a></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><i className="far fa-envelope"></i></div>
                                            <div className="content">
                                                <h5>Sorularınız mı var?</h5>
                                                <p><a href="mailto:info@ydzotomotiv.com.tr">info@ydzotomotiv.com.tr</a></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6">
                                <div className="contact-form-wrap">
                                    <div className="login-wrap">
                                        <h3 className="widget-title">Bize mesaj <span>gönderin</span></h3>

                                        <Form onFinish={onFinish} form={form} className="login-form">
                                            <Form.Item className="form-grp" name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lütfen adınızı ve soyadınızı girin.'
                                                    }
                                                ]}
                                                label="Ad Soyad"
                                                labelAlign="right"
                                                labelCol={{ span: 24 }}>
                                                <Input placeholder="Ad Soyad" />
                                            </Form.Item>

                                            <Form.Item className="form-grp" name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lütfen e-posta adresinizi girin.'
                                                    }
                                                ]}
                                                label="E-Posta Adresi"
                                                labelAlign="right"
                                                labelCol={{ span: 24 }}>
                                                <Input placeholder="E-posta Adresi" />
                                            </Form.Item>

                                            <Form.Item className="form-grp" name="message"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lütfen mesajınızı girin.'
                                                    },
                                                ]}
                                                label="Mesajınız"
                                                labelAlign="right"
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input.TextArea Rows={4} placeholder="Mesajınızı buraya yazın..." />
                                            </Form.Item>

                                            <Form.Item>
                                                <button disabled={isLoading} className="btn" htmlType="submit">Mesajımı Gönder</button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="contact-map" style={{ marginTop: "70px" }}>
                    <iframe 
                        src="https://maps.google.com/maps?q=YDZ%20OTOMOT%C4%B0V%20KURTK%C3%96Y&t=&z=15&ie=UTF8&iwloc=&output=embed"
                        width="100%"
                        height="575"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"></iframe>
                </div>

                {/* <div className="contact-map"> */}
                {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
                        width="600"
                        height="575"
                        frameBorder="0"
                        style="border:0;"
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    /> */}
                {/* </div> */}
            </main>
        </div>
    )
}

export default Contact;