import React from 'react'

const Inventory = props => {
    let carData = props.car;
    

    var carClass = "";

    switch (carData.Group_Str) {
        case "EKO":
            carClass = "Ekonomik Sınıf"
            break;

        case "ORTA":
            carClass = "Orta Sınıf"
            break;
        case "ÜST":
            carClass = "Üst Sınıf"
            break;
        case "PREMİUM":
            carClass = "Premium Sınıf"
            break;
        case "SUV":
            carClass = "Suv Sınıf"
            break;
        case "MINIBUS":
            carClass = "Minibüs";
            break
        default:
            break;
    }

    const modalOpenFunction=()=>{
        props.modalCarInfoState(carData)
        props.modalCarState(true)
    }

    return (
        <div key={carData.ID} className="inventory-list-item">
            <div className="inventory-list-thumb">
                <img src={carData.Image_Path ? carData.Image_Path : carData.Image1_Path} alt="" width={291} height={239} />
                <ul className="inv-thumb-meta">
                    <li><i className="fas fa-info"></i></li>
                </ul>
            </div>
            <div className="inventory-list-content">
                <div className="inv-content-top">
                    <ul>
                        <li className="option">
                            <a href={() => false}>{carData.Transmission} Vites</a>
                            <a href={() => false} className="new" >{carData.Fuel}</a>
                        </li>
                        {carData.Total_Rental !== undefined ?
                            <li className="price">Toplam: {carData.Total_Rental} TL</li>
                            :
                            <li className="price">{carData.Provizyon} TL<span>/gün</span></li>
                        }
                    </ul>
                </div>
                <h4>{`${carData.Brand} ${carData.Type}`}</h4>
                {carData.Daily_Rental !== undefined &&
                    <h6><a href={() => false}>Günlük Fiyat: {carData.Daily_Rental} TL</a></h6>
                }
                {carData.Days !== undefined &&
                    <p className="location">Kira Günü: {carData.Days}</p>
                }
                <div className="inv-item-meta">
                    <ul>

                        <li className="call" onClick={e=>modalOpenFunction(e)}>
                            <a href={() => false} className="call"><i className="fas fa-shopping-basket"></i>Şimdi Kirala</a>
                        </li>
                        {carData.Driver_Age !== undefined ?
                            <li style={{ marginRight: 10 }}>{carData.Driver_Age} Yaş Üzeri </li>
                            :
                            <li>Model: {carData.model} </li>
                        }
                        <li>Kapı: {carData.Doors}</li>
                        <li>{String(carClass)}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Inventory;
