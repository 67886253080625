import React from 'react'
import { useHistory } from 'react-router-dom';
import { message } from 'antd';

const InventorySearch = props => {
    let history = useHistory();
    let carData = props.car;
    let dateDetails = props.dateDetails;
    let Locations = props.Locations;

    var carClass = "";

    switch (carData.Group_Str) {
        case "EKO":
            carClass = "Ekonomik Sınıf"
            break;

        case "ORTA":
            carClass = "Orta Sınıf"
            break;
        case "ÜST":
            carClass = "Üst Sınıf"
            break;
        case "PREMİUM":
            carClass = "Premium Sınıf"
            break;
        case "SUV":
            carClass = "Suv Sınıf"
            break;
        case "MINIBUS":
            carClass = "Minibüs"
            break;

        default:
            break;
    }

    const NavigateToDetails = () => {
        const hide = message.loading('İşleminiz gerçekleştiriliyor..', 0);

        hide();
        setTimeout(() => {
            history.push("/kirala", { car: carData, dateDetails: dateDetails, Locations: Locations })
        }, 1000);
    }

    return (
        <div key={carData.ID} className="inventory-list-item">
            <div className="inventory-list-thumb">
                <img src={carData.Image_Path ? carData.Image_Path : carData.Image1_Path} alt="" width={291} height={239} />

            </div>
            <div className="inventory-list-content">
                <div className="inv-content-top" >
                    <ul>
                        <li className="option">
                            <a href={() => false}>{carData.Transmission} Vites</a>
                            <a href={() => false} className="new">{carData.Fuel}</a>
                        </li>
                        <li className="price">Toplam Bedel: {carData.Total_Rental} TL</li>
                    </ul>
                </div>
                <h4>{`${carData.Brand} ${carData.Type}`}</h4>
                <p style={{ fontWeight: '500' }} className="location"><p className="location" style={{ fontWeight: '700' }}>{carData.Daily_Rental} TL</p>/gün</p>
                <p className="location" style={{ fontWeight: '700' }}>Toplam Gün : {carData.Days}</p>
                <p className="location" style={{ fontWeight: '700' }}>Toplam Km Sınırı: {carData.Km_Limit} Km</p>

                <div className="inv-item-meta">
                    <ul>
                        <li className="call">
                            <a href={() => false} onClick={NavigateToDetails} className="call"><i className="fas fa-credit-card"></i>Aracı Hemen Kirala</a>
                        </li>
                        <li className="call">
                            <a href={() => false} className="call"><i className="fas fa-user"></i>{carData.Driver_Age} Yaş</a>
                        </li>
                        <li className="call">
                            <a href={() => false} className="call"><i className="fas fa-car"></i>{carData.Doors} Kapı</a>
                        </li>
                        <li className="call">
                            <a href={() => false} className="call"><i className="fas fa-id-card"></i>{carData.Driving_License_Age} Yıl</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default InventorySearch;