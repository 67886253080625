import React from "react";


const SalesAggrement = () => {
  return (
    <div>
      <main>
        <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${"img/bg/breadcrumb_bg.jpg"})` }} data-background="img/bg/breadcrumb_bg.jpg">
          <div className="container" style={{ paddingTop: 50 }}>
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-content text-center">
                  <h2>Mesafeli Satış Sözleşmesi</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Ana Sayfa</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Mesafeli Satış Sözleşmesi
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="loan-calculator-area pb-120">
          <div className="container">
            <div className="calculator-loan">
              <div className="row">
                <div className="col-md-12">
                  <div className="loan-result-wrap">
                    <h3>Mesafeli Satış Sözleşmesi</h3>
                    <p>
                      13.06.2003 tarih ve 25137 sayılı resmi gazetede yayınlanan 'mesafeli sözleşmeler uygulama usul ve esasları hakkında yönetmelik' gereğince internet üzerinden gerçekleştirilen satışlar için sözleşme yapılması zorunluluğu getirilmiştir. Sözleşme detayları aşağıda belirtildiği gibidir.                    </p>
                    <h6>
                      SÖZLEŞMENİN TARAFLARI
                    </h6>
                    <p>
                      SATICI:www.ydzotomotiv.com.tr
                      Adres: Kurtköy, Koru Sk. No:11, 34912 Pendik/İstanbul
                      Tel: +90 (543) 472 11 99
                      Email: info@ydzotomotiv.com.tr
                      ALICI: Müşterilerin www.ydzotomotiv.com.tr sitesinden hizmet talep ederken kullandıkları adres ve iletişim bilgileri esas alınır.</p>
                    <h6>
                      SÖZLEŞMENİN KONUSU
                    </h6>
                    <p>
                      Alıcının Satıcıya ait www.ydzotomotiv.com.tr web sitesinden elektronik ortamda sipariş verdiği, sözleşmede bahsi geçen nitelikleri haiz ve yine sözleşmede satış fiyatı belirtilen mal/hizmetin satışı ve teslimi ile ilgili olarak 4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Uygulama Esas ve Usulleri Hakkında Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır. Alıcı, satışa konu mal/hizmetlerin temel nitelikleri, satış fiyatı, ödeme şekli, teslimat koşulları vs. satışa konu mal/hizmet ile ilgili tüm ön bilgiler ve “cayma” hakkı konusunda bilgi sahibi olduğunu, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında mal/hizmeti sipariş verdiğini is bu sözleşme hükümlerince kabul ve beyan eder. www.ydzotomotiv.com.tr sitesinde ödeme sayfasında yer alan SİTE KULLANIM ŞARTLARI ve TRANSFER ŞARTLARI bu sözleşmenin ayrılmaz parçalarıdır.
                    </p>
                    <h6>
                      SÖZLEŞME TARİHİ
                    </h6>
                    <p>
                      Müşterilerin www.ydzotomotiv.com.tr sitesinden talep ettiği hizmetin kapsadığı tarihler esastır.
                    </p>
                    <h6>
                      MAL/HİZMETİN TESLİMİ, SÖZLEŞMENİN İFA YERİ VE TESLİM ŞEKLİ
                    </h6>
                    <p>
                      Satıcının sunduğu hizmet, müşterinin belirlediği transfer yerine göre yine müşterinin hizmet talep ederken belirlediği şekilde sağlanır.
                    </p>
                    <h6>
                      TESLİMAT MASRAFLARI VE İFASI
                    </h6>
                    <p>
                      Teslimat masrafları alıcıya aittir. Satıcı, web sitesinde, ilan ettiği rakamın üzerinde hizmet talep edenlerin ücretinin kendisince karşılanacağını yada kampanya dahilinde ücretsiz teslimat yapacağını beyan etmişse, teslimat masrafı satıcıya aittir. Herhangi bir nedenle mal/hizmet bedeli ödenmez veya banka kayıtlarında iptal edilir ise, satıcı mal/hizmetin teslimi yükümlülüğünden kurtulmuş kabul edilir ve önceden web sitesinde yer alan TRANSFER ŞARTLARIgereğince ilgili işlem yapılır.
                    </p>
                    <h6>
                      ALICININ BEYAN VE TAAHHÜTLERİ
                    </h6>
                    <p>
                      Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura iade edilmelidir. Mal/hizmetin tesliminden sonra alıcıya ait kredi kartının alıcının kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşunun mal/hizmet bedelini satıcıya ödememesi halinde, Alıcı kendisine teslim edilmiş olması kaydıyla mal/hizmeti 3 (Üç) gün içinde satıcıya göndermekle yükümlüdür. Bu takdirde teslimat giderleri alıcıya aittir.
                    </p>
                    <h6>
                      SÖZLEŞMEYE KONU MAL/HİZMETİN ÖZELLİKLERİ
                    </h6>
                    <p>
                      Mal/hizmetin cinsi ve türü, miktarı, marka/modeli, rengi ve tüm vergiler dâhil satış bedeli www.ydzotomotiv.com.tr adlı web sitesindeki mal/hizmet tanıtım sayfasında yer alan bilgilerde ve iş bu sözleşmenin ayrılmaz parçası sayılan faturada belirtildiği gibidir.
                    </p>
                    <h6>
                      GENEL HÜKÜMLER
                    </h6>
                    <p>
                      Acente, hizmetin başlamasına veya devamına engel teşkil edecek transfer aracında yaşanacakmekanik arıza, trafik kazası, operasyonel aksaklıklar, hava muhalefeti, trafik sıkışıklığı,terör olayları, kolluk kuvvetlerinin uygulamaları, vb. nedenler dolayı transferinizigerçekleştiremeyeceğini anladığı andan itibaren en kısa sürede alternatif bir araç sağlanmayaçalışılacak ve/veya aksi durumda da bilgi verecektir.Yukarıda belirtmiş olduğumuz durumlarda ödemesi alınmış olan transfer ücretinin iadesitarafınıza kesintisiz olarak iade edilecek ve/veya böyle bir durumda Tüketicinin kendiimkânlarınızla sağlamış olduğu ulaşımdan kaynaklanan fiyat farkı da Acente tarafından tüketiciye ödenecektir.
                    </p>
                    <h6>
                      YÜRÜRLÜK
                    </h6>
                    <p>
                      İş bu sözleşme gerektiği taktir de 2 nüsha halinde tanzim edilerek taraflarca imzalanacak olup,sözleşmeden kaynaklanan anlaşmazlıklarda, 4925 Sayılı Karayolu Taşıma Kanununu maddeleriuygulanır.  Bu sözleşmeden doğacak olan uyuşmazlık konusunda İstanbul mahkemesi ve icradaireleri yetkilidir.Sözleşmeyi kendim ve/veya rezervasyon formunda belirtmiş olduğum isimleri yazılı kişiler adınaokuyup, kabul ettiğimi beyan ettim.Bu beyanım; rezervasyon işlemini kendim yerine bir başkasının yürütmüş ve/veya imzalamış olmasıhalinde dahi geçerlidir.Tüketici olarak adımıza düzenlenmiş olan Web Sitesi Mesafeli Satış Sözleşmesi  ve “Şartlar veKoşullar” sayfalarında belirtilen hükümlerinin tamamını, göndermiş olduğum rezervasyon formundayer alan bilgilerimin doğruluğunun sorumluluğunu, 4925 Sayılı Karayolu Taşıma Kanununu veAcentenin web sitesindeki bilgileri okuyup, anlayıp kabul ettiğimi beyan ederim.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default SalesAggrement;
