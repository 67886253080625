import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Header() {

    const { pathname, search, hash } = useLocation()

    return (
        <div>
            <header className="header-style-two inner-page-header" >
                <div id="sticky-header" className="main-header menu-area">
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler"><i className="fas fa-bars"></i></div>
                                <div className="menu-wrap" >
                                    <nav className="menu-nav show">
                                        <div className="logo">
                                            <Link to="/" className="sticky-logo-none"><img src="img/logo/b_logo.png" alt="Logo" /></Link>
                                            <Link to="/" className="sticky-logo-active"><img src="img/logo/b_logo.png" alt="Logo" /></Link>
                                        </div>
                                        <div className="navbar-wrap main-menu d-none d-lg-flex">
                                            <ul className="navigation">
                                                <li className={pathname === "/" ? "active dropdown" : "dropdown"}><Link to="/" style={{ color: 'black' }}>REZERVASYON</Link>
                                                </li>
                                                <li className={pathname === "/araclar" ? "active dropdown" : "dropdown"}><Link to="/araclar" style={{ color: 'black' }}>ARAÇLAR</Link></li>
                                                <li className={pathname === "/kosullar" ? "active dropdown" : "dropdown"}><Link to="/kosullar" style={{ color: 'black' }}>KİRALAMA KOŞULLARI</Link></li>
                                                <li className={pathname === "/kurumsal-kiralama" ? "active dropdown" : "dropdown"}><Link to="/kurumsal-kiralama" style={{ color: 'black' }}>KURUMSAL KİRALAMA</Link></li>
                                                <li className={pathname === "/iletisim" ? "active dropdown header-btn d-flex align-items-center" : "dropdown header-btn d-flex align-items-center"}><Link to="/iletisim" className="btn btn-iletisim" >BİZE ULAŞIN</Link></li>

                                            </ul>
                                        </div>
                                       
                                    </nav>
                                </div>
                                <div className="mobile-menu">
                                    <div className="menu-backdrop"></div>
                                    <div className="close-btn" style={{ color: 'black' }}><i className="fas fa-times"></i></div>

                                    <nav className="menu-box" style={{ backgroundColor: "white" }}>
                                        <div className="nav-logo"><a href="/"><img src="img/logo/b_logo.png" alt="" title="" /></a>
                                        </div>
                                        <div className="menu-outer">
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix">
                                                <li><a style={{ color: 'black' }} href="https://www.facebook.com/pages/category/Car-Rental/YDZ-Otomotiv-Ara%C3%A7-Kiralama-101788948684346/"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a style={{ color: 'black' }} href="https://www.instagram.com/ydzotoarac/"><i className="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="collapse-search-body" className="collapse-search-body collapse">
                        <div className="search-body">
                            <div className="container custom-container">
                                <form action="#">
                                    <div className="form-item">
                                        <input name="search" placeholder="Type here..." />
                                        <button type="submit"><i className="fas fa-search"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/*
             <script src="js/vendor/jquery-3.5.0.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/isotope.pkgd.min.js"></script>
            <script src="js/imagesloaded.pkgd.min.js"></script>
            <script src="js/jquery.magnific-popup.min.js"></script>
            <script src="js/jquery.nice-select.min.js"></script>
            <script src="js/owl.carousel.min.js"></script>
            <script src="js/jquery.odometer.min.js"></script>
            <script src="js/jquery.accrue.min.js"></script>
            <script src="js/jquery.appear.js"></script>
            <script src="js/jarallax.min.js"></script>
            <script src="js/slick.min.js"></script>
            <script src="js/ajax-form.js"></script>
            <script src="js/wow.min.js"></script>
            <script src="js/aos.js"></script>
            <script src="js/plugins.js"></script>
            <script src="js/main.js"></script>
            */ }


        </div>
    )
}

export default Header;