import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';

function Checkout() {
    const { state } = useLocation();
    const history = useHistory();
    const [responseHtml, setresponseHtml] = useState(state?state.response:[]);
    const [onlineFetchData, setonlineFetchData] = useState(state?state.onlineFetchData:[]);
    const [onlineFetchResponse, setonlineFetchResponse] = useState("");
    const [SelectedCar, setSelectedCar] = useState(state?state.selectedCar:[]);
    const [UserValues, setUserValues] = useState(state?state.UserValues:[]);
    const [DateDetails, setDateDetails] = useState(state?state.DateDetails:[]);
    const [Options, setOptions] = useState(state?state.options:[])
    const [Locations, setLocations] = useState(state?state.Locations:[])
    const [SelectedBirtday, setSelectedBirtday] = useState(state?state.SelectedBirtday:[])
    // setresponseHtml(res.data);

    useEffect(() => {
        paymentDetailPerSecond();
    }, [])

    const MakeReservation = async () => {
        let DataModel = {
            "Car_ID": String(SelectedCar.Car_ID),
            "Rez_ID": String(SelectedCar.Rez_ID),
            "Currency": "TL",
            "Name": String(onlineFetchData.buyer.name),
            "Sur_Name": String(onlineFetchData.buyer.surname),
            "Mobile": String(onlineFetchData.buyer.gsmNumber),
            "Mail_Adress": String(onlineFetchData.buyer.email),
            "Flight_Number": String(UserValues.flightNo),
            "Pickup_ID": String(SelectedCar.Pickup_ID),
            "Pickup_Year": String(DateDetails.Drop_Off_Year),
            "Pickup_Month": String(DateDetails.Pickup_Month),
            "Pickup_Day": String(DateDetails.Pickup_Day),
            "Pickup_Hour": String(DateDetails.Pickup_Hour),
            "Pickup_Min": String(DateDetails.Pickup_Min),
            "Drop_Off_Year": String(DateDetails.Drop_Off_Year),
            "Drop_Off_Month": String(DateDetails.Drop_Off_Month),
            "Drop_Off_Day": String(DateDetails.Drop_Off_Day),
            "Drop_Off_Hour": String(DateDetails.Drop_Off_Hour),
            "Drop_Off_Min": String(DateDetails.Drop_Off_Min),
            "Drop_Off_ID": String(DateDetails.Drop_Off_ID),
            "Private_Driver": "",
            "Baby_Seat": Options.BabySeatCount > 0 ? "ON" : "",
            "Child_Seat": Options.ChildSeatCount > 0 ? "ON" : "",
            "Navigation": Options.NavigationCount > 0 ? "ON" : "",
            "CDW": Options.CDWCount > 0 ? "ON" : "",
            "SCDW": Options.SCDWCount > 0 ? "ON" : "",
            "TGI": Options.TGICount > 0 ? "ON" : ""
        }

        await axios.post('https://mangayazilim.com/ydzapi/api/Home/SaveReservation', DataModel)
            .then((response) => {
                if (response.data === "failure") {
                    message.error("Hata! Rezervasyon yapılırken bir sorun oluştu.");
                    history.push(`/tesekkurler`);
                } else {
                    message.success("Ödeme başarılı.");
                    setTimeout(() => {
                        history.push(`/tesekkurler`,{payment:"success"});
                    }, 2000);
                }
                console.log('response: ', response)

            })
            .catch((err) => {
                message.error("Hata! Rezervasyon yapılırken bir sorun oluştu.");
                console.log('REZ ERROR', err);
            })
    }

    const paymentDetailPerSecond = async () => {
        let timerId = setInterval(
            async () =>
                await axios
                    .post("https://mangayazilim.com/ydzapi/api/home/PaymentDetail", {
                        conversationId: onlineFetchData.conversationId,
                        paymentId: "",
                        paymentConversationId: onlineFetchData.conversationId,
                    })
                    .then(async (res) => {
                        if (res.data.paymentStatus === "SUCCESS" || res.data.paymentStatus === "FAILURE") {
                            if (res.data.paymentStatus === "FAILURE") {
                                clearInterval(timerId);
                                setonlineFetchResponse(res.data.paymentStatus);
                                message.error("Ödeme alınamadı!")
                            }
                            if (res.data.paymentStatus === "SUCCESS") {
                                clearInterval(timerId);
                                MakeReservation();


                                const hide = message.loading('Mesajınız gönderiliyor..', 0);

                                axios.post("https://us-central1-avcilarkuyumcusu-5f835.cloudfunctions.net/app/sendEmailYDZ", {
                                    formData: {
                                        email: UserValues.email,
                                        message: `
                                            GENEL BİLGİLER  <br>\n
                                            Kiralanan Araç Bilgileri: ${String(SelectedCar.Brand + ' ' + SelectedCar.Type + ' ' + SelectedCar.Fuel + ', ' + SelectedCar.Transmission)} <br>\n
                                            Alış Yeri:${Locations.Location_Name}<br>\n
                                            Alış Tarihi:${DateDetails.Pickup_Day}/${DateDetails.Pickup_Month}/${DateDetails.Pickup_Year} - ${DateDetails.Pickup_Hour}:${DateDetails.Pickup_Min}<br>\n
                                            İade Yeri:${Locations.Location_Name}<br>\n
                                            İade Tarihi: ${DateDetails.Drop_Off_Day}/${DateDetails.Drop_Off_Month}/${DateDetails.Drop_Off_Year} - ${DateDetails.Drop_Off_Hour}:${DateDetails.Drop_Off_Min}<br>\n
                                            Kira Gün Sayısı:${SelectedCar.Days}
                                            <br>\n<br>\n
                                            Taksit Sayısı:${onlineFetchData.installment}<br>\n
                                            Ödenen Toplam Tutar: ${onlineFetchData.price}
                                            <br>\n<br>\n
                                            
                                            Bebek Koltuğu: ${Options.BabySeatCount} <br>\n
                                            Çocuk Koltuğu: ${Options.ChildSeatCount} <br>\n
                                            Navigasyon: ${Options.NavigationCount} <br>\n
                                            Mini Hasar Güvencesi(CDW): ${Options.CDWCount} <br>\n
                                            Tam Kaza Güvencesi (SCDW): ${Options.SCDWCount} <br>\n
                                            Lastik Cam Far Sigortası (LCF): ${Options.TGICount} <br>\n
                                            Ek Sürücü: ${Options.AdditionalDriverCount}
                                            <br>\n<br>\n

                                            KİŞİSEL BİLGİLER <br>\n
                                            Ad: ${UserValues.name} <br>\n
                                            Soyad: ${UserValues.surname} <br>\n
                                            Mail Adresi: ${UserValues.email} <br>\n

                                            Cep Telefonu: ${UserValues.mobilePhone} <br>\n
                                            Telefon Numarası: ${UserValues.phone} <br> \n
                                            Doğum Tarihi: ${SelectedBirtday} <br> \n
                                            Tc Kimlik No:${UserValues.identity} <br>\n
                                            Şehir: ${UserValues.city} <br>\n
                                            Uçuş No: ${UserValues.flightNo} <br>\n
                                            Ehliyet No: ${UserValues.licenseNo} <br>\n
                                            Adres: ${UserValues.address} 
                                            <br>\n<br>\n

                                            FATURA BİLGİLERİ <br>\n
                                            Firma Adı: ${UserValues.companyName}
                                            Vergi Dairesi: ${UserValues.taxPlace} <br>\n
                                            Vergi Numarası: ${UserValues.taxNumber} <br>\n
                                            Fatura Şehri: ${UserValues.invoiceCity} <br>\n
                                            Fatura Adresi: ${UserValues.invoiceAddress} <br>\n
                                            <br>\n<br>\n

                                            `,
                                        subject: "Yeni Araç Kiralama Satın Alması Gerçekleşti",
                                        name: UserValues.name + " " + UserValues.surname
                                    }, type: "contact"
                                }).then((res) => {
                                    console.log('res: ', res)
                                    if (res.status === 200) {
                                        hide();
                                        message.success('Talebiniz başarılı bir şekilde alınmıştır.')
                                    } else {
                                        hide();
                                        message.error('Mesaj gönderilirken bir hata oluştu, lütfen tekrar deneyin.');
                                    }
                                })
                                    .catch((err) => {
                                        hide();
                                        message.error(String(err));
                                    })


                            }
                        } else if (res.data.paymentStatus === "CALLBACK_THREEDS") {
                            clearInterval(timerId);
                            setonlineFetchResponse("CALLBACK_THREEDS");
                        }
                    }),
            2000
        );
    };

    const Iframe = (props) => {
        const writeHTML = (frame) => {
            if (!frame) {
                return;
            }
            const Width = parseFloat(frame.contentWindow.document.body.scrollWidth) + 300 + "px";
            const Height = parseFloat(frame.contentWindow.document.body.scrollHeight) + 600 + "px";


            let doc = frame.contentDocument;
            doc.open();
            doc.write(props.content);
            doc.close();
            frame.style.width = Width;
            frame.style.height = Height;
        };
        return <iframe title="iframe" src="about:blank" frameBorder="0" ref={writeHTML} style={{ overflow: "hidden", zIndex: "9999" }} />;
    };

    return <div>
        {onlineFetchResponse === "FAILURE" ? (
            <div className="cart-main-area pt-90 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="item-empty-area text-center">
                                <div className="item-empty-area__icon mb-30">
                                    <i className="pe-7s-close-circle"></i>
                                </div>
                                <div className="item-empty-area__text">
                                    <h1>Ödeme banka tarafından reddedildi</h1> <br />  <p onClick={() => {
                                        history.goBack(-2);
                                    }}>Tekrar Dene</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : onlineFetchResponse === "CALLBACK_THREEDS" ? (
            <div className="cart-main-area pt-90 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="item-empty-area text-center">
                                <div className="item-empty-area__icon mb-30">
                                    <i className="pe-7s-close-circle"></i>
                                </div>
                                <div className="item-empty-area__text">
                                    <h1>Hata! Ödeme yapılamadı</h1> <br />
                                    <p onClick={() => {
                                        history.goBack(-2);
                                    }}>Tekrar Dene</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : responseHtml && (
            <div className="d-flex align-items-center justify-content-center">
                <Iframe content={responseHtml} />
            </div>
        )
        }
    </div>
}

export default Checkout;
