import React, { useState } from 'react'

function Kosullar() {

    const [OpenedId, setOpenedId] = useState(0);

    return (
        <div>
            <main>
                <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${'img/bg/breadcrumb_bg.jpg'})` }} data-background="img/bg/breadcrumb_bg.jpg">
                    <div className="container" style={{ paddingTop: 50 }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2>Kiralama Koşulları</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Ana Sayfa</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Kiralama Koşulları</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="faq-area position-relative fix pt-120 pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-0 order-lg-2">
                                <div className="faq-img">
                                    <img src="https://www.dejavurentacar.com/wp-content/uploads/2019/02/Antalya-Ara%C3%A7-Kiralama-Fiyatlar%C4%B1.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="faq-wrap">
                                    <div onClick={() => setOpenedId(0)} className="faq-set active">
                                        <a href={() => false} className={OpenedId === 0 ? "active" : ""}>Genel Kiralama Koşulları {OpenedId === 0 ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</a>
                                        <div className="content" style={{ display: OpenedId === 0 && 'block' }}>
                                            <p style={{ fontWeight: '700' }}>1. SÜRÜCÜ YAŞI VE SÜRÜCÜBELGESİ:</p>
                                            <p style={{ paddingBottom: 20 }}>Aracı kiralayan misafir 23 yaşını doldurmuş ve en az 2 yıllık ehliyet sahibi olmalıdır.</p>
                                            <p style={{ fontWeight: '700' }}>2. KİRALAMA SÜRESİ:</p>
                                            <p style={{ paddingBottom: 20 }}>Minimum kiralama süresi 3 gündür. 3 gün ve üzeri kiralamar için adrese teslim seçeneği mevcuttur.</p>
                                            <p style={{ fontWeight: '700' }}>3. KİLOMETRE SINIRI:</p>
                                            <p>Kiralama sınırı günlük 250 km olup, aylık 4000 km’dir. Km aşımı olduğunda, km başına 0.80 kuruştan değerlendirilir.</p>
                                        </div>
                                    </div>
                                    <div onClick={() => setOpenedId(1)} className="faq-set">
                                        <a href={() => false} className={OpenedId === 1 ? "active" : ""}>İptal / İade Şartları {OpenedId === 1 ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</a>
                                        <div className="content" style={{ display: OpenedId === 1 && "block" }}>
                                            <p>Ödemeli rezervasyonlarda aracı teslim alma saatinden 48 saat öncesine kadar rezervasyon iptal edilirse kiralama ücretinin tamamı YDZ Otomotiv tarafından iade edilecektir.</p>
                                            <p>Ödemeli rezervasyonlarda aracın teslim edileceği saate 48 saat ile 2 saat arasında kaldığında 3 günlük kiralama ücreti kesilerek geriye kalan tutar YDZ Otomotiv tarafından iade edilecektir. (3 günlük rezervasyonlarda iade yapılmayacaktır )</p>
                                            <p>Ödemeli rezervasyonlarda müşterilerimize ait araçlar teslim alma saatinden itibaren 2 saat bekletilecek olup, bu dört saat içerisinde müşterilerimiz araçlarını teslim alabileceklerdir. 2 saat sonrasında rezervasyon sistem tarafından otomatik olarak iptal edilecek ve ücret iadesi yapılmayacaktır.</p>
                                            <p>Ödemeli rezervasyonlarda eksik belgeler yüzünden teslim alınamayan veya kredi kartının araç teslim anında temin edilmemesi durumlarında 48 saatten az bir süre kalan iptal prosedürü uygulanacaktır.</p>
                                            <p>Kiralayanın kendi isteği ile aracı erken teslim etmesi halinde kalan gün sayısına ait kira ücretin geri iadesi söz konusu değildir.</p>
                                        </div>
                                    </div>
                                    <div onClick={() => setOpenedId(2)} className="faq-set">
                                        <a href={() => false} className={OpenedId === 2 ? "active" : ""}>Kurumsal Kiralama{OpenedId === 2 ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</a>
                                        <div className="content" style={{ display: OpenedId === 2 && "block" }}>
                                            <p style={{ paddingBottom: 20 }}>Uzun dönem araç kiralama yapan firmalar yıl boyunca avantajlı fiyatlarla araç kiralıyor!</p>
                                            <p style={{ paddingBottom: 20 }}>Bu sistemde özellikle kurumsal kimlik taşıyan müşteri grubuna hizmet verilmektedir. Sözleşme süresince tüm periyodik bakım masrafarı, kaza, hırsızlık ve trafik sigortaları, ikame araç tahsisi, 24 saat yol yardım hizmeti, MTV tarafımızca karşılanmaktadır. Bu hizmetler karşılığında ticari kuruluşlar ve kurumsal firmalar için vergi, zaman, personel gibi önemli avantajlar sağlanılmaktadır. Kesilen aylık faturaları masraf kaydedebilmekte, bu işlerle uğraşacak personel bulundurmamakta, zamandan tasarruf edilebilmekte daha da önemlisi öz sermaye asli yatırımlara ayırabilinmektedir. Giderler kontrol altına alındığı gibi ikinci el kaybından da etkilenilmemiş olunur.</p>
                                            <p style={{ paddingBottom: 20 }}>YDZ Otomotiv tecrübesi ve uzman kadrosuyla sizden gelen talepleri ön planda tutarak en uygun aracı seçmenizde yardımcı olmaktadır.</p>
                                            <p style={{ paddingBottom: 20 }}>Tüm soru ve önerileriniz için info@ydzotomotiv.com.tr  adresinden bize ulaşabilirsiniz.</p>
                                            <p>12 ay üzeri uzun dönem kiralamalarınız için  info@ydzotomotiv.com.tr​  adresine mail atabilirsiniz.</p>
                                        </div>
                                    </div>
                                    <div onClick={() => setOpenedId(3)} className="faq-set">
                                        <a href={() => false} className={OpenedId === 3 ? "active" : ""}>Hakkımızda {OpenedId === 3 ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</a>
                                        <div className="content" style={{ display: OpenedId === 3 && "block" }}>
                                            <p style={{ paddingBottom: 20 }}>Araç kiralama denilince Türkiye’de akıllara gelen ilk markalardan biri olma yolunda emin adımlarla ilerleyen YDZ Otomotiv, 2010 yılında İstanbul’da kurulmuştur. Araç kiralamada daima güvenli ve konforlu araç, yüksek hizmet performansı ve ekonomik fiyat garantisi sunmakta olan YDZ Otomotiv müşterilerine en uygun şekilde hizmet vermek için hizmet ağını her geçen gün büyütmektedir. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faq-bg-dots" data-background="img/bg/faq_dots_bg.jpg"></div>
                </div>
            </main>
        </div >
    )
}

export default Kosullar;