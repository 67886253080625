import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <main>
        <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${"img/bg/breadcrumb_bg.jpg"})` }} data-background="img/bg/breadcrumb_bg.jpg">
          <div className="container" style={{ paddingTop: 50 }}>
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-content text-center">
                  <h2>Gizlilik Politikası</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Ana Sayfa</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Gizlilik Politikası
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="loan-calculator-area pb-120">
          <div className="container">
            <div className="calculator-loan">
              <div className="row">
                <div className="col-md-12">
                  <div className="loan-result-wrap">
                    <h3>Gizlilik Politikası</h3>
                    <p>
                      YDZ Otomotiv,kendisine ait www.ydzotomotiv.com.tr internet sitesindeki formlar aracılığıyla (isminiz,e-posta adresiniz,telefon numaranız v.b) kişisel verileri isteyebilir.Bu kişisel bilgiler rezervasyonunuzu gerçekleştirdikten sonra size ulaşılması gereken durumlarda kullanılacaktır.Bu bilgiler ayrıca sizlere elektronik posta ve basın bültenleri göndermek,basılı yayınlar ve yazışmalar için kullanılabilir.İşbu kişisel bilgiler YDZ Otomotiv tarafından 3.kişilere kiralanmaz,satılmaz ve hiçbir şekilde kullandırılmaz.
                    </p>
                    <h6>
                      Cookie (Çerez)Kullanımı

                    </h6>
                    <p>
                      YDZ Otomotiv'e ait www.ydzotomotiv.com.tr sitesinde cookie(çerez) olarak tabir edilen tanımla bilgileri kullanılmaktadır. Bir web sitesine girdiğinizde, sunucunun tarayıcınıza verdiği simgeye cookie (çerez) adı verilir. Çerezler çok çeşitli tür veri depo edebilirler. Çerezler bir sitenin işlevselliğini arttırır ve size sitenin kullanımını daha doğru incelememizde yardımcı olur. Sizin açık izniniz olmadıkça çerezler asla kişisel bilgi toplamaz.
                    </p>
                    <p>
                      YDZ Otomotiv , www.ydzotomotiv.com.tr web sitesi ile istatistiksel olarak site ziyaretlerini, toplam ziyaret sayısını, sitenin herbir sayfasını ziyaret edenlerin sayısını, ziyaretçilerin Internet servis sağlayıcılarının alan adlarını takip eder. Bu süreçte hiçbir kişisel bilgi kullanılmamaktadır.Buna ek olarak sitemizi ziyaret ettiğinizde IP adresinizi kaydedilmektedir. Bu adres sadece sizin Internet Servis Sağlayıcınızı tanımlar ve sizin kişisel bilgilerinizle ilgili herhangi bir veri içermez.
                    </p>
                    <h6>
                      Ödeme Güvenliği
                    </h6>
                    <p>www.ydzotomotiv.com.tr üzerinden gerçekleştirilen araç kiralama işlemlerinizde SSL adı verilerin güvenlik teknolojisi kullanılmaktadır.Kredi kartı bilgileriniz hiçbir ortamda kesinlikle saklanmayıp onay için doğrudan bankanıza iletilmektedir.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default PrivacyPolicy;
