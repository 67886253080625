import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-credit-cards/lib/styles-compiled.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Cars from './pages/Cars';
import CarsSearch from './pages/CarsSearch';
import About from './pages/About';
import Contact from './pages/Contact';
import Kosullar from './pages/Kosullar';
import Kirala from './pages/Kirala';
import KurumsalKiralama from './pages/KurumsalKiralama';
import Checkout from './pages/Checkout';
import Thanks from './pages/Thanks';

import SalesAggrement from './pages/SalesAggrement'; // Satış Sözleşmesi
import PrivacyPolicy from "./pages/PrivacyPolicy"//Gizlilik Politikası

import $ from 'jquery';

const Routing = () => {

  useEffect(() => {

    if ($('.menu-area li.dropdown ul').length) {
      $('.menu-area .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
    }

    //Mobile Nav Hide Show
    if ($('.mobile-menu').length) {

      var mobileMenuContent = $('.menu-area .main-menu').html();
      $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      //Dropdown Button
      $('.mobile-menu li.dropdown .dropdown-btn').on('click', function () {
        $(this).toggleClass('open');
        $(this).prev('ul').slideToggle(500);
      });
      //Menu Toggle Btn
      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });

      //Menu Toggle Btn
      $('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function () {
        $('body').removeClass('mobile-menu-visible');
      });
    }


  }, [])
  return (
    <Router
      forceRefresh={true}
    >
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/araclar" component={Cars} />
        <Route path="/sonuclar" component={CarsSearch} />
        <Route path="/kirala" component={Kirala} />
        <Route path="/odeme" component={Checkout} />
        <Route path="/tesekkurler" component={Thanks} />
        <Route path="/kosullar" component={Kosullar} />
        <Route path="/kurumsal-kiralama" component={KurumsalKiralama} />
        <Route path="/hakkimizda" component={About} />
        <Route path="/iletisim" component={Contact} />

   
        <Route path="/gizlilik-politikasi" component={PrivacyPolicy} />
        <Route path="/satis-sozlesmesi" component={SalesAggrement} />
        
        {/* <Route path="/service" component={Service} /> */}
      </Switch>
      <Footer />
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
