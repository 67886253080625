import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="footer-brand-area white-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 order-2 order-md-0">
              <div className="footer-logo">
                <a href="/">
                  <img src="img/logo/w_logo.png" alt="" />
                </a>
              </div>
            </div>
            {/* 
                            <div className="col-lg-8 col-md-6">
                            <div className="row footer-brand-active">
                                <div className="col">
                                    <div className="footer-brand-item">
                                        <a href="#"><img src="img/brand/footer_brand01.png" alt="" /></a>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="footer-brand-item">
                                        <a href="#"><img src="img/brand/footer_brand02.png" alt="" /></a>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="footer-brand-item">
                                        <a href="#"><img src="img/brand/footer_brand03.png" alt="" /></a>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="footer-brand-item">
                                        <a href="#"><img src="img/brand/footer_brand04.png" alt="" /></a>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="footer-brand-item">
                                        <a href="#"><img src="img/brand/footer_brand05.png" alt="" /></a>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="footer-brand-item">
                                        <a href="#"><img src="img/brand/footer_brand03.png" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}
          </div>
        </div>
      </div>
      <footer className="footer-bg" data-background="img/bg/footer_bg.jpg">
        <div className="footer-top-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-widget black-bg mb-50">
                  <div className="fw-title mb-30">
                    <h4 className="title">Bize Ulaşın</h4>
                  </div>
                  <div className="footer-text mb-45">
                    <p>YDZ Otomotiv tecrübesi ve uzman kadrosuyla sizden gelen talepleri ön planda tutarak en uygun aracı seçmenizde yardımcı olmaktadır.</p>
                  </div>
                  <div className="footer-contact">
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="fas fa-phone"></i>
                        </div>
                        <div className="content">
                          <span>Telefon</span>
                          <p>
                            <a href="tel:905434721199">+90 (543) 472 11 99</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="content">
                          <span>E-Posta</span>
                          <p>
                            <a href="mailto:info@support.com">info@ydzotomotiv.com.tr</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </div>
                        <div className="content">
                          <span>Adres</span>
                          <p>Kurtköy, Koru Sk. No:11, 34912 Pendik/İstanbul</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="footer-right-wrap">
                  <div className="row justify-content-between">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <div className="footer-widget mb-50">
                        <div className="fw-title mb-30">
                          <h4 className="title">YDZ Otomotiv</h4>
                        </div>
                        <div className="fw-link">
                          <ul>
                            <li>
                              <Link to="/iletisim">İletişim</Link>
                            </li>
                            <li>
                              <Link to="/araclar">Araçlar</Link>
                            </li>
                            <li>
                              <Link to="/kurumsal-kiralama">Kurumsal Kiralama</Link>
                            </li>
                            <li>
                              <Link to="/kosullar">Kiralama Koşulları</Link>
                            </li>
                            <li>
                              <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
                            </li>
                            <li>
                              <Link to="/satis-sozlesmesi">Mesafeli Satış Sözleşmesi</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-6">
                      <div className="footer-widget mb-50">
                        <div className="fw-title mb-30">
                          <h4 className="title">Takip Edin</h4>
                        </div>
                        <div className="footer-social">
                          <ul>
                            <li>
                              <a href="https://www.facebook.com/pages/category/Car-Rental/YDZ-Otomotiv-Ara%C3%A7-Kiralama-101788948684346/">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/ydzotoarac/">
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                            {/* <li><a href="#"><i className="fab fa-twitter"></i></a></li> */}
                            {/* <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-payment-method-wrap">
                    <div className="content">
                      <p>3D Secure ve SHA256 şifreleme altyapımız ile güvenli bir şekilde ödeme yapabilirsiniz.</p>
                    </div>
                    <div className="payment-card">
                      <img style={{width:"80%"}} src="img/footer-bank/footer-bank.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="copyright-text">
                  <p>© 2021 YDZ Otomotiv. Tüm Hakları Saklıdır. Website: Influencer Cat</p>
                </div>
              </div>
              <div className="col-md-4">
                <a href="#" onClick={window.scrollTo(0, 0)}>
                  <button className="scroll-top scroll-to-target" data-target="html">
                    <i className="fas fa-angle-up"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
