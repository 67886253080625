import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Inventory from '../components/Inventory';
import { Modal, Space, message, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';


function Cars() {
    let history = useHistory();


    const [CarsList, setCarsList] = useState([]);
    const [SearchCarsList, setSearchCarsList] = useState([]);
    const [modalCar, setmodalCar] = useState(false)
    const [modalCarInfo, setmodalCarInfo] = useState({})

    //new state
    const [Locations, setLocations] = useState([]);
    const [WorkDays, setWorkDays] = useState([]);

    // const [SpliceLimit, setSpliceLimit] = useState(6);
    // const [Categories, setCategories] = useState([]);
    // const [SelectedCat, setSelectedCat] = useState("");

    const tomorrow = moment().add(1, 'days');
    const nextTomorrow = moment().add(2, 'days');

    const [selectedStartTime, setselectedStartTime] = useState(moment(tomorrow).set({ h: 10, m: 0 }));
    const [selectedEndTime, setselectedEndTime] = useState(moment(nextTomorrow).set({ h: 10, m: 0 }));
    const [type, settype] = useState(1);
    const [SelectedPickUpLocationID, setSelectedPickUpLocationID] = useState("")
    const [SelectedDropLocationID, setSelectedDropLocationID] = useState("");


    const modalCarState = (boolean) => {
        setmodalCar(boolean)
    }

    const modalCarInfoState = (value) => {
        setmodalCarInfo(value)
    }

    const modalClear = () => {
        setmodalCar(false)
        setmodalCarInfo({})
    }


    const GetCars = async () => {
        let myCars = [];


        await axios.get("https://mangayazilim.com/ydzapi/api/Home/GetCars")
            .then((response) => {
                Object.values(response.data.CityRent.Cars).forEach((item) => {
                    myCars.push(item);
                })
            }).finally(() => {
                setCarsList(myCars);
                setSearchCarsList(myCars);
                console.log('mycars: ', myCars)
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }


    const SearchData = (e) => {
        let filtered = SearchCarsList.filter(i => i.Brand.toLowerCase().includes(e.toLowerCase()))

        setSearchCarsList(filtered);

        if (e.length === 0) {
            setSearchCarsList(CarsList);
        }
    }

    function onOk(value) {

        const selectedDay = moment(value);
        const dayName = moment(value).format("dddd");

        let seciliSaat = parseFloat(String(moment(selectedDay).format("HH:mm")).replace(':', ''));

        function showSucces() {
            if (type === 1) {
                setselectedStartTime(value)
            } else {
                setselectedEndTime(value);
            }
            message.success(`${type === 1 ? 'Alış' : 'İade'} tarihi ve saati müsait.`);
        }

        function showError(hours) {
            message.error(`${dayName} günleri ${hours.start} ve ${hours.end} arasında çalışmaktayız. Lütfen bu saat aralığında seçiniz.`);
            return false;
        }

        switch (parseFloat(moment(selectedDay).format("d"))) {
            case 0:
                if (seciliSaat < parseFloat(String(WorkDays[6].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[6].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[6].Work_Time_Start,
                        end: WorkDays[6].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 1:
                if (seciliSaat < parseFloat(String(WorkDays[0].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[0].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[0].Work_Time_Start,
                        end: WorkDays[0].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 2:
                if (seciliSaat < parseFloat(String(WorkDays[1].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[1].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[1].Work_Time_Start,
                        end: WorkDays[1].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 3:
                if (seciliSaat < parseFloat(String(WorkDays[2].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[2].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[2].Work_Time_Start,
                        end: WorkDays[2].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 4:
                if (seciliSaat < parseFloat(String(WorkDays[3].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[3].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[3].Work_Time_Start,
                        end: WorkDays[3].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 5:
                if (seciliSaat < parseFloat(String(WorkDays[4].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[4].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[4].Work_Time_Start,
                        end: WorkDays[4].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            case 6:
                if (seciliSaat < parseFloat(String(WorkDays[5].Work_Time_Start).replace(':', '')) || seciliSaat > parseFloat(String(WorkDays[5].Work_Time_End).replace(':', ''))) {
                    let hours = {
                        start: WorkDays[5].Work_Time_Start,
                        end: WorkDays[5].Work_Time_End
                    }
                    showError(hours)
                } else {
                    showSucces();
                }
                break;

            default:
                break;
        }
    }

    const GoToSearch = async () => {
        const pickupDate = moment(selectedStartTime).format("DD-MM-YYYYTHH:mm")
        const dropDate = moment(selectedEndTime).format("DD-MM-YYYYTHH:mm");

        if (pickupDate !== undefined && pickupDate !== null && dropDate !== undefined && dropDate !== null) {
            const hide = message.loading('İşleminiz gerçekleştiriliyor..', 0);

            let dataModel = {
                "Pickup_ID": String(SelectedPickUpLocationID),
                "Pickup_Day": String(pickupDate).split('T')[0].split('-')[0],
                "Pickup_Month": String(pickupDate).split('T')[0].split('-')[1],
                "Pickup_Year": String(pickupDate).split('T')[0].split('-')[2],
                "Pickup_Hour": String(pickupDate).split('T')[1].split(':')[0],
                "Pickup_Min": String(pickupDate).split('T')[1].split(':')[1],
                "Drop_Off_ID": String(SelectedDropLocationID),
                "Drop_Off_Day": String(dropDate).split('T')[0].split('-')[0],
                "Drop_Off_Month": String(dropDate).split('T')[0].split('-')[1],
                "Drop_Off_Year": String(dropDate).split('T')[0].split('-')[2],
                "Drop_Off_Hour": String(dropDate).split('T')[1].split(':')[0],
                "Drop_Off_Min": String(dropDate).split('T')[1].split(':')[1],
                "Currency": "TL"
            }
            
            await axios.post("https://mangayazilim.com/ydzapi/api/Home/Search", dataModel)
                .then((res) => {
                    if (res.data === "failure") {
                        hide();
                        message.error('Seçtiğiniz kritelerde göre arama sonucu bulunamadı. Lütfen farklı gün ve tarih seçiniz.');
                    } else {
                        hide();
                        setTimeout(() => {
                            history.push("/sonuclar", { cars: res.data.Grsrent.Cars, dateDetails: dataModel, Locations: Locations })
                        }, 1000);
                    }
                })
                .catch((err) => {
                    hide();
                    console.log('search err:', err);
                    message.error(String(err));
                })
        } else {
            message.error('Lütfen tüm bilgileri doldurun.');
            console.log('err')
        }
    }

    const GetLocations = async () => {
        await axios.get("https://mangayazilim.com/ydzapi/api/Home/GetLocations")
            .then((res) => {
                let days = Object.values(res.data.AytuRent.Location.WorkDays)
                setWorkDays(days[0]);
                setLocations(res.data.AytuRent.Location);
                setSelectedPickUpLocationID(res.data.AytuRent.Location.Location_ID);
                setSelectedDropLocationID(res.data.AytuRent.Location.Location_ID);
            })
            .catch((err) => console.log('Get Location Error: ', err))
    }

    const FetchAllData = async () => {
        await GetCars();
        await GetLocations();
    }



    useEffect(() => {
        GetCars();
        FetchAllData();

    }, [])

    return (
        <div>
            <main>
                <Modal
                    title={<div className="small-title mb-20">
                        <h4><i className="flaticon-searching-car"></i> ARAÇ MI <span>ARIYORSUNUZ?</span></h4>
                    </div>}
                    visible={modalCar}
                    footer={null}
                    onOk={GoToSearch}
                    onCancel={e => modalClear(e)}
                    width={1200}
                    className='p-0'
                    style={{ padding: "0" }}
                    bodyStyle={{ padding: "0" }}


                >
                    <section className="car-search-area gray-bg">
                        <div className="container">
                            <div className="row">
                                <div className="car-search-wrap w-100">
                                    <div className="col-12">

                                        <form action="#" className="car-search-form">
                                            <div className="row align-items-end">
                                                <div className="col custom-col-5">
                                                    <label>ALIŞ LOKASYONU</label>
                                                    <select name="name" className="selected selected-border-none">
                                                        <option value={Locations.Location_Name}>{Locations.Location_Name}</option>
                                                    </select>
                                                </div>
                                                <div className="col custom-col-5">
                                                    <label>ALIŞ TARİHİ</label>
                                                    <Space direction="vertical" size={12} className="w-100">
                                                        <DatePicker
                                                            className="selected-border-none"
                                                            onClick={() => settype(1)}
                                                            style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                backgroundColor: '#F3F3F3',
                                                                padding: '22px 40px 22px 20px'
                                                            }}
                                                            // className="selected"
                                                            defaultValue={selectedStartTime}
                                                            showTime={{ format: 'HH:mm' }}
                                                            allowClear
                                                            locale={locale}
                                                            format="YYYY-MM-DD HH:mm"
                                                            onOk={onOk}
                                                        />
                                                    </Space>
                                                </div>
                                                <div className="col custom-col-5">
                                                    <label>İADE LOKASYONU</label>
                                                    <select name="name" className="selected selected-border-none">
                                                        <option value={Locations.Location_Name}>{Locations.Location_Name}</option>
                                                    </select>
                                                </div>
                                                <div className="col custom-col-5">
                                                    <label>İADE TARİHİ</label>
                                                    <Space direction="vertical" size={12} className="w-100">
                                                        <DatePicker
                                                            className="selected-border-none"
                                                            onClick={() => settype(0)}
                                                            style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                backgroundColor: '#F3F3F3',
                                                                padding: '22px 40px 22px 20px'
                                                            }}
                                                            // className="selected"
                                                            defaultValue={selectedEndTime}
                                                            showTime={{ format: 'HH:mm' }}
                                                            allowClear
                                                            locale={locale}
                                                            format="YYYY-MM-DD HH:mm"
                                                            onOk={onOk}
                                                        />
                                                    </Space>
                                                </div>
                                                <div className="col custom-col-5">
                                                    <a href={() => false} onClick={GoToSearch} className="btn">ARAÇLARI LİSTELE</a>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="car-search-shape"><img src="img/images/car_search_shape.png" alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal>


                <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${'img/bg/breadcrumb_bg.jpg'})` }} data-background="img/bg/breadcrumb_bg.jpg">
                    <div className="container" style={{ paddingTop: 50 }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2>Araçlar</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href={() => false}>Ana Sayfa</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Araçlar</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inventory-list-area gray-lite-bg pt-120 pb-120">
                    <div className="container">
                        <div className="inventory-meta-wrap mb-50">
                            <div className="row align-items-center">
                                <div className="col-xl-8 col-lg-7 col-md-6">
                                    <div className="inventory-top-meta">
                                        <ul>
                                            <li className="find">Listelenen Araç Sayısı: <span>{SearchCarsList.length}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">

                            <div className="col-xl-8 col-lg-7 col-md-9">

                                {SearchCarsList.map((car) => {
                                    return (
                                        <Inventory car={car} modalCarState={modalCarState} modalCarInfoState={modalCarInfoState} />
                                    )
                                })}
                            </div>

                            <div className="col-xl-4 col-lg-5 col-md-9">
                                <aside className="inventory-sidebar">
                                    <div className="widget inventory-widget">
                                        <div className="inv-widget-title mb-25">
                                            <h5 className="title">Araçları Filtrele</h5>
                                        </div>
                                        <form action="#" className="sidebar-find-car">
                                            <div className="form-grp search-box">
                                                <input type="text" onChange={(search) => SearchData(search.target.value)} placeholder="Araç ara" />
                                                <button><i className="fas fa-search"></i></button>
                                            </div>
                                            { /*
                                            <div className="form-grp">
                                                <i className="flaticon-gasoline-pump"></i>
                                                <select name="name" className="selected">
                                                    {SearchCarsList.length > 0 &&
                                                        Object.values(SearchCarsList).map((fuel) => {
                                                            return (
                                                                <option value={fuel.Fuel}>{fuel.Fuel}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        
                                            <div className="form-grp">
                                                <i className="flaticon-car-4"></i>
                                                <select name="name" className="selected">
                                                    <option value="">Araç Tipi</option>
                                                    <option value="">Jaguer M1 Hybrid</option>
                                                    <option value="">Audi New Bom 800</option>
                                                    <option value="">Audi A8 Hybrid</option>
                                                    <option value="">Toyota T86LM</option>
                                                </select>
                                            </div>
                                            <div className="form-grp">
                                                <i className="fas fa-dollar-sign"></i>
                                                <select name="name" className="selected">
                                                    <option value="">Fiyat Aralığı</option>
                                                    <option value="">100 - 250</option>
                                                    <option value="">250 - 500</option>
                                                    <option value="">500 - 1000</option>
                                                    <option value="">1000 - 2000</option>
                                                    <option value="">2000 - 5000</option>
                                                </select>
                                            </div>
                                            <div className="form-grp">
                                                <i className="flaticon-speedometer"></i>
                                                <select name="name" className="selected">
                                                    <option value="">Vites Tipi:</option>
                                                    <option value="">Manuel</option>
                                                    <option value="">Otomatik</option>
                                                </select>
                                            </div>
                                             
                                            <button className="btn">Filtreleri Uygula</button>
                                            */}
                                        </form>
                                    </div>

                                    { /*<div className="widget inventory-widget">
                                        <div className="inv-widget-title mb-25">
                                            <h5 className="title">Popüler Araçlar</h5>
                                        </div>
                                        <div className="sidebar-fv-active">
                                            <div className="sidebar-fv-item" data-background="img/images/sidebar_featured_vehicle01.jpg">
                                                <div className="fv-top-tag">
                                                    <ul>
                                                        <li><a href="#">For Rent</a></li>
                                                        <li><a className="new" href="#">New Car</a></li>
                                                    </ul>
                                                </div>
                                                <div className="sidebar-fv-content">
                                                    <h5>$11,000<span>/gün</span></h5>
                                                    <p><a href="inventory-details.html">BMW 8 - Series 2 Door</a></p>
                                                </div>
                                            </div>
                                            <div className="sidebar-fv-item" data-background="img/images/sidebar_featured_vehicle02.jpg">
                                                <div className="fv-top-tag">
                                                    <ul>
                                                        <li><a href="#">For Rent</a></li>
                                                        <li><a className="new" href="#">New Car</a></li>
                                                    </ul>
                                                </div>
                                                <div className="sidebar-fv-content">
                                                    <h5>$29,000<span>/mo</span></h5>
                                                    <p><a href="inventory-details.html">Audi - A4 Sedan XL</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div >
    )
}

export default Cars;