import React from 'react'
import { Form, Input, message } from 'antd';
import axios from 'axios';

function KurumsalKiralama() {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Success:', values);
        //         address: "Adres"
        // description: "Açıklama"
        const hide = message.loading('Mesajınız gönderiliyor..', 0);

        axios.post("https://us-central1-avcilarkuyumcusu-5f835.cloudfunctions.net/app/sendEmailYDZ", {
            formData: {
                email: values.email,
                message: `Şehir: ${values.city} <br>\n
                Cep Telefon: ${values.mobilePhone} <br>\n
                Telefon: ${values.phone} <br> \n
                Vergi Numarası: ${values.taxNumber} <br>\n
                Vergi Dairesi: ${values.taxPlace} <br>\n
                Adres: ${values.address} <br>\n
                Açıklama: ${values.description} <br>`,
                subject: "Kurumsal Kiralama Formu " + values.companyName,
                name: values.companyOwner
            }, type: "contact"
        }).then((res) => {
            console.log('res: ', res)
            if (res.status === 200) {
                hide();
                form.resetFields();
                message.success('Talebiniz başarılı bir şekilde alınmıştır.')
            } else {
                hide();
                message.error('Mesaj gönderilirken bir hata oluştu, lütfen tekrar deneyin.');
            }
        })
            .catch((err) => {
                hide();
                message.error(String(err));
            })
    };

    return (
        <div>
            <main>
                <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${'img/bg/breadcrumb_bg.jpg'})` }} data-background="img/bg/breadcrumb_bg.jpg">
                    <div className="container" style={{ paddingTop: 50 }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2>Kurumsal Kiralama</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Kurumsal Kiralama</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="loan-calculator-area pb-120">
                    <div className="container">
                        <div className="calculator-loan">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="loan-result-wrap">
                                        <div className="section-title text-center mb-30">
                                            <h2 className="title">Uzun dönem araç kiralama yapan firmalar yıl boyunca avantajlı fiyatlarla araç kiralıyor!</h2>
                                            <div className="line"><img src="img/images/title_line.png" alt="" /></div>
                                            <p>Bu sistemde özellikle kurumsal kimlik taşıyan müşteri grubuna hizmet verilmektedir.
                                                Sözleşme süresince tüm periyodik bakım masrafarı, kaza, hırsızlık ve trafik sigortaları, ikame araç tahsisi, 24 saat yol yardım hizmeti, MTV tarafımızca karşılanmaktadır.
                                                <br /><br />Bu hizmetler karşılığında ticari kuruluşlar ve kurumsal firmalar için vergi, zaman, personel gibi önemli avantajlar sağlanılmaktadır.
                                                Kesilen aylık faturaları masraf kaydedebilmekte, bu işlerle uğraşacak personel bulundurmamakta, zamandan tasarruf edilebilmekte daha da önemlisi öz sermaye asli yatırımlara ayırabilinmektedir.
                                                Giderler kontrol altına alındığı gibi ikinci el kaybından da etkilenilmemiş olunur.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="loan-result-wrap">
                                        <div className="section-title text-center mb-30">
                                            <h2 className="title">Tecrübe ve Uzman Kadro</h2>
                                            <div className="line"><img src="img/images/title_line.png" alt="" /></div>
                                            <p>YDZ Otomotiv tecrübesi ve uzman kadrosuyla sizden gelen talepleri ön planda tutarak en uygun aracı seçmenizde yardımcı olmaktadır. <br /><br />Tüm soru, önerileriniz ve 12 ay üzeri uzun dönem kiralamalarınız için info@ydzotomotiv.com.tr adresinden bize ulaşabilirsiniz.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="loan-calculator-area pb-120">
                    <div className="container">
                        <div className="calculator-loan">
                            <div className="col-12">
                                <div className="contact-form-wrap">
                                    <div>
                                        <div className="small-title mb-20">
                                            <h3 className="widget-title"><span>Kurumsal Kiralama Formu</span></h3>
                                        </div>
                                        <Form onFinish={onFinish} form={form} className="login-form">
                                            <div className="row">
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="companyName"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Lütfen Firma Adı giriniz'
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Firma Adı" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="companyOwner"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Lütfen Firma Yetkili Adı giriniz'
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Firma Yetkilisi" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                type:"email",
                                                                message: 'Lütfen Mail Adresi giriniz'
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Mail Adresi" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="mobilePhone"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Lütfen Cep Telefonu giriniz',
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Cep Telefonu" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="phone"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Lütfen Telefon Numarası giriniz',
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Telefon Numarası" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="city"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Şehir bilgisi giriniz',
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Şehir" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="address"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Lütfen Adres giriniz'
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Adres" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="taxPlace"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Vergi Dairesi giriniz',
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Vergi Dairesi" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-grp col-md-4">
                                                    <Form.Item name="taxNumber"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Vergi Numarası giriniz',
                                                            },
                                                        ]}>
                                                        <Input placeholder="*Vergi Numarası" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-grp col-md-12">
                                                    <Form.Item name="description"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Lütfen Açıklama giriniz'
                                                            },
                                                        ]}>
                                                        <Input.TextArea Rows={4} placeholder="*Açıklama" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-grp col-md-5">
                                                </div>
                                                <div className="form-grp col-md-2">
                                                    <button className="btn" htmlType="submit">Gönder</button>
                                                </div>
                                                <div className="form-grp col-md-5">
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
export default KurumsalKiralama;