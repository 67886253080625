import React, { useEffect, useState } from 'react'
import InventorySearch from '../components/InventorySearch';
import { useLocation } from "react-router-dom";

const CarsSearch = () => {
    const { state } = useLocation()

    const [CarsList, setCarsList] = useState(state.cars !== undefined && state.cars.length !== 0 ? state.cars : []);
    const [SearchCarsList, setSearchCarsList] = useState(state.cars !== undefined && state.cars.length !== 0 ? state.cars : []);
    const [dateDetails, setdateDetails] = useState(state.dateDetails !== undefined && state.dateDetails.length !== 0 ? state.dateDetails : [])
    const [Locations, setLocations] = useState(state.Locations !== undefined && state.Locations.length !== 0 ? state.Locations : [])

    const GetCars = async () => {
        let myCars = [];

        Object.values(state.cars).forEach((item) => {
            myCars.push(item);
            setCarsList(myCars);
            setSearchCarsList(myCars);
        })

    }


    useEffect(() => {
        GetCars();
    }, [])

    return (
        <div>
            <main>
                <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: `url(${'img/bg/breadcrumb_bg.jpg'})` }} data-background="img/bg/breadcrumb_bg.jpg">
                    <div className="container" style={{ paddingTop: 50 }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2>Araçlar</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Ana Sayfa</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Araçlar</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inventory-list-area gray-lite-bg pt-120 pb-120">
                    <div className="container">
                        <div className="inventory-meta-wrap mb-50">
                            <div className="row align-items-center">
                                <div className="col-xl-8 col-lg-7 col-md-6">
                                    <div className="inventory-top-meta">
                                        <ul>
                                            <li className="find">Listelenen Araç Sayısı: <span>{SearchCarsList.length}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">

                            <div className="col-xl-12 col-lg-12 col-md-9">
                                {SearchCarsList.map((car) => {
                                    return (
                                        <InventorySearch car={car} imagePath={car.Image_Path} dateDetails={dateDetails} Locations={Locations} />
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default CarsSearch;